import React, {  } from 'react';
import PropTypes from 'prop-types'
// Material
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
// Icons
import ErrorIcon from '@material-ui/icons/Error';

const ExamPageFailure = ({
  message,
  onRetry,
  onExamList
}) => {
  const theme = useTheme()
  return (
    <Paper elevation={6} style={{padding: theme.spacing(3)}}>
      <Typography align="center"><ErrorIcon style={{fontSize: '5em', color: theme.palette.error.main}}/></Typography>
      <Typography align="center" variant="h4">Oops, something went wrong!</Typography>
      <Typography align="center">
        {message}. Click here to <Button onClick={onRetry}>retry</Button>
      </Typography>
      <Typography align="center">
        <Button onClick={onExamList}>See list of exams</Button>
      </Typography>
    </Paper>
  )
}
ExamPageFailure.propTypes = {
  message: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired,
  onExamList: PropTypes.func.isRequired
}

export default ExamPageFailure;
