import React, {} from 'react'
import PropTypes from 'prop-types'
// MUI
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
// Icons
import ErrorIcon from '@material-ui/icons/Error';

function Failure({
  onRetry
}) {
  const theme = useTheme()
  return (
    <Paper elevation={5} style={{width: "100%", padding: theme.spacing(3)}}>
      <Typography align="center" variant="h4">
        <ErrorIcon style={{fontSize: '2em', color: theme.palette.error.main}}/>
      </Typography>
      <Typography align="center" color="secondary">
        Failed to prepare your reviewer.
      </Typography>
      <Typography align="center">
        <Button onClick={onRetry}>Retry</Button>
      </Typography>
    </Paper>
  )
}
Failure.propTypes = {
  onRetry: PropTypes.func.isRequired
}

export default Failure