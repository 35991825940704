import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
// MUI
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const ChoiceCorrect = withStyles(({palette, spacing}) => ({
  root: {
    color: palette.success.main,
    '& .Mui-checked': {
      color: palette.success.main,
    },
  }
}))((props) => <FormControlLabel color="success" {...props}/>)

const ChoiceWrong = withStyles(({palette, spacing}) => ({
  root: {
    color: palette.error.main,
    '& .Mui-checked': {
      color: palette.error.main,
    },
  }
}))((props) => <FormControlLabel color="error" {...props}/>)

const Choice = ({
  choice,
  isCorrect,
  onSelect
}) => {
  if(choice.isCorrect) {
    return (
      <ChoiceCorrect
        color="error"
        key={'choice-'+choice.id}
        control={<Checkbox color="primary" checked={isCorrect} onChange={(e) => onSelect(e, choice.id)} />}
        label={choice.text}
      />
    )
  } else {
    return (
      <ChoiceWrong
        color="error"
        key={'choice-'+choice.id}
        control={<Checkbox color="primary" checked={isCorrect} onChange={(e) => onSelect(e, choice.id)} />}
        label={choice.text}
      />
    )
  }
}
Choice.propTypes = {
  choice: PropTypes.object.isRequired,
  isCorrect: PropTypes.bool,
  onSelect: PropTypes.func
}

function Question({
  text,
  choices,
  onSelect,
  value,
  liveCheck,
  highlight
}) {
  const isCorrect = (choiceId) => {
    return (value ||{}).choiceId == choiceId
  }
  return (
    <Fragment>
      <Typography variant="h3">{text}</Typography>
      <br />
      <FormGroup>
        {
          choices.map((choice, index) => {
            if(!highlight) {
              return (
                <FormControlLabel
                  key={'choice-'+choice.id}
                  control={<Checkbox color="primary" checked={isCorrect(choice.id)} onChange={(e) => onSelect(e, choice.id)} />}
                  label={choice.text}
                />
              )
            }
            if(liveCheck) {
              return (
                <Choice
                  choice={choice}
                  isCorrect={isCorrect(choice.id)}
                  onSelect={onSelect}
                />
              )
            } else {
              if(choice.isCorrect && isCorrect(choice.id)) {
                return (
                  <ChoiceCorrect
                    color="error"
                    key={'choice-'+choice.id}
                    control={<Checkbox color="primary" checked={isCorrect(choice.id)} onChange={(e) => onSelect(e, choice.id)} />}
                    label={choice.text}
                  />
                )
              } else {
                if(value == undefined) {
                  return (
                    <FormControlLabel
                      key={'choice-'+choice.id}
                      control={<Checkbox color="primary" checked={isCorrect(choice.id)} onChange={(e) => onSelect(e, choice.id)} />}
                      label={choice.text}
                    />
                  ) 
                } else {
                  return (
                    <Choice
                      choice={choice}
                      isCorrect={isCorrect(choice.id)}
                      onSelect={onSelect}
                    />
                  )
                }
              }
            }
          })
        }
      </FormGroup>
    </Fragment>
  )
}
Question.propTypes = {
  text: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  liveCheck: PropTypes.bool,
  highlight: PropTypes.bool
}
Question.defaultProps = {
  liveCheck: false,
  highlight: true
}


export default Question