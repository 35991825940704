import React, {} from 'react'
import PropTypes from 'prop-types'
// Material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function RetireDialog({
  open,
  onContinue,
  onDiscard
}) {

  return (
    <Dialog
      open={open}
      onClose={onDiscard}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Do you want want to retire this question?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Retiring this question will not be use to any exam but will be keep so that previous exam record is maintained.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDiscard} color="secondary">
          Cancel
        </Button>
        <Button onClick={onContinue} color="primary">
          Yes, Retire
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RetireDialog.propTypes = {
  open: PropTypes.bool,
  onContinue: PropTypes.func,
  onDiscard: PropTypes.func
}


export default RetireDialog