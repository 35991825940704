import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import ExamMode from 'components/common/ExamMode';
import {
  NAME_COLUMN_XS,
  CODE_COLUMN_XS,
  BUTTONS_COLUMN_XS,
} from '../constants';

const useStyles = makeStyles((theme) => ({
  item: {
    marginTop: 8,
    marginBottom: 8,
  },
  typographyName: {
    marginLeft: 8*8,
  },
}));

const CourseCategoryItem = ({
  id,
  name,
  hasExams,
  canReview,
  inReviewMode,
  removeReviewButton
}) => {
  const history = useHistory();
  const classes = useStyles();

  const handleClick = id => event => {
    event.stopPropagation();

    history.push(`/review/${id}`);
  }

  return (
    <Grid item container xs={12} justify='space-between' classes={{ root: classes.item }}>
      <Grid item xs={NAME_COLUMN_XS + CODE_COLUMN_XS}>
        <Typography variant='subtitle1' className={classes.typographyName}>
          {name}
        </Typography>
      </Grid>

      {
        inReviewMode && !removeReviewButton && (
          <Grid container item xs={BUTTONS_COLUMN_XS} justify='flex-end'>
            <ExamMode.Hide>
              <Button
                key={id}
                variant='outlined'
                onClick={handleClick(id)}
                disabled={!canReview || !hasExams}
              >
                Review
              </Button>
            </ExamMode.Hide>
          </Grid>
        )
      }
    </Grid>
  )
}

CourseCategoryItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  hasExams: PropTypes.bool,
  canReview: PropTypes.bool,
  inReviewMode: PropTypes.bool,
  removeReviewButton: PropTypes.bool
}

CourseCategoryItem.defaultProps = {
  hasExams: false,
  canReview: false,
  inReviewMode: false,
  removeReviewButton: false
}

export default memo(CourseCategoryItem);
