import React, { useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkIfExamCheatsEnabled } from 'reduxConfig/selectors';
// MUI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useTheme } from '@material-ui/core/styles';
// Query
import { BEGIN_EXAM_REVIEW } from 'constants/queries';
import { BEGIN_EXAM_REVIEW_CHECK } from 'constants/mutations';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
// Machine
import { GlobalStateContext } from '../../global.state'
import { useActor } from '@xstate/react'
// Form
import { useFormik } from 'formik'
import * as yup from 'yup'
// Components
import Loading from './Loading'
import Failure from './Failure'
import Question from '../../common/Question'
import Result from '../../common/Result'

function ReviewPage () {
  const history = useHistory()
  const theme = useTheme()
  const examCheatsEnabled = useSelector(checkIfExamCheatsEnabled);

  const Services = useContext(GlobalStateContext)
  const ReviewState = useActor(Services.ReviewService)
  const params = useParams()
  const competenceName = window._.get(ReviewState[0].context, 'name', '')
  const questions = window._.get(ReviewState[0].context, 'questions', '')
  const competence = window._.get(ReviewState[0].context, 'competence', {})
  const page = ReviewState[0].context.page
  const totalPages = Math.ceil(questions.length/10)
  const reviewResult = ReviewState[0].context.result

  const form = useFormik({
    initialValues: {
      questions: []
    },
    validationSchema: yup.object({
      questions: yup.array()
        .min(1, 'Please answer one of any questions.')
        .of(yup.object({
          questionId: yup.number(),
          choiceId: yup.number()
        }))
    }),
    onSubmit(values) {
      // console.log(values)
      checkResult({
        variables: {
          courseItemId: Number(params.courseItemId),
          questions: values.questions
        }
      })
      ReviewState[1]('SUBMIT')
    }
  })
  const [beginTrial] = useLazyQuery(BEGIN_EXAM_REVIEW, {
    variables: {
      courseItemId: Number(params.courseItemId)
    },
    onCompleted(data, clientOptions) {
      ReviewState[1]({type: 'SUCCESS', data: data.beginExamReview})
    },
    onError(error, clientOptions) {
      ReviewState[1]({type: 'FAILURE', data: error})
    }
  })
  const [checkResult] = useMutation(BEGIN_EXAM_REVIEW_CHECK, {
    onCompleted(data, clientOptions) {
      ReviewState[1]({type: 'CHECK_SUCCESS', data: data.beginExamReviewCheck})
    },
    onError(error, clientOptions) {
      ReviewState[1]('CHECK_FAILED')
    }
  })
  const handlePageChange = (event, newPage) => {
    ReviewState[1]({type: 'SET_PAGE', data: newPage})
  }
  const handleChoice = (question, choiceId) => {
    // console.log(question, choiceId)
    const questions = [...form.values.questions]
    const questionIndex = window._.findIndex(questions, (o) => o.questionId == question.id)

    if(questionIndex > -1) {
      const formQuestion = questions[questionIndex]
      formQuestion.choiceId = Number(choiceId)
    } else {
      questions.push({
        questionId: Number(question.id),
        choiceId: Number(choiceId)
      })
    }

    form.setFieldValue('questions', questions)
  }
  const handleAlertClose = () => {
    ReviewState[1]('CLOSE')
  }
  const handleRedirect = () => {
    history.replace('/')
  }

  useEffect(() => {
    ReviewState[1]('FETCH')
    beginTrial()
  }, [])
  return (
    <Grid style={{padding: theme.spacing(3)}} container spacing={2}>
      <Snackbar open={Boolean(form.errors.questions)} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
        <Alert severity="error" variant="filled" elevation={6}>
          {form.errors.questions}
        </Alert>
      </Snackbar>
      <Snackbar open={ReviewState[0].matches('check.failure')} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} onClose={handleAlertClose}>
        <Alert severity="error" variant="filled" elevation={6} onClose={handleAlertClose}>
          Error occured when checking.
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        {
          (() => {
            if(ReviewState[0].matches('fetching')) {
              return (
                <Loading />
              )
            }
            if(ReviewState[0].matches('failure')) {
              return (
                <Failure onRetry={() => ReviewState[1]('RETRY')}/>
              )
            }
            if(ReviewState[0].matches('result')) {
              return (
                <Paper elevation={5}>
                  <Result
                    name={competenceName}
                    correct={window._.get(reviewResult, 'correct', 0)}
                    wrong={window._.get(reviewResult, 'wrong', 0)}
                    items={window._.get(reviewResult, 'totalItems', 0)}
                    grade={window._.get(reviewResult, 'grade', 0.00)}
                    onRedirect={handleRedirect}
                  />
                </Paper>
              )
            }

            return (
              <Paper elevation={5} style={{width: "100%", padding: theme.spacing(3)}}>
                {/*<Typography align="center" variant="h6">{competenceName}</Typography>*/}
                <Box style={{margin: -theme.spacing(3), marginBottom: theme.spacing(3), padding: theme.spacing(3), backgroundColor: theme.palette.primary.main, color: theme.palette.common.white}}>
                  <Typography variant="h4">
                    { window._.get(competence, 'category.course.name', '') }
                  </Typography>
                  <Typography variant="h5">
                    { window._.get(competence, 'category.name', '') }
                  </Typography>
                  <Typography variant="h5">
                    { window._.get(competence, 'name', '') }
                  </Typography>
                </Box>

                {
                  questions.slice((page-1),page).map((question, index) => (
                    <Question
                      highlight={true}
                      liveCheck={examCheatsEnabled}
                      key={'question-'+question.id}
                      text={question.text}
                      choices={question.choices}
                      onSelect={(e, choiceId) => handleChoice(question, choiceId)}
                      value={window._.find(form.values.questions, (o) => o.questionId == question.id)}
                    />
                  ))
                }
                <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                  <span>&nbsp;</span>
                  <Pagination count={questions.length} color="primary" page={page} onChange={handlePageChange}/>
                  {
                    (() => {
                      if(questions.length == page) {
                        return (
                          <Button variant="contained" color="primary" onClick={form.handleSubmit}>{ReviewState[0].matches('check.process')? 'Checking. . .' :'Submit'}</Button>
                        )
                      }

                      return (
                        <span>&nbsp;</span>
                      )
                    })()
                  }
                </Box>
              </Paper>
            )
          })()
        }
      </Grid>
    </Grid>
  )
}


export default ReviewPage