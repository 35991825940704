import React, { useEffect, useContext, createRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom';
// Material
import { makeStyles, useTheme, styled, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// Icons
import ListIcon from '@material-ui/icons/List';
import ErrorIcon from '@material-ui/icons/Error';
// GraphQL
import { IMPORT_COURSE } from '../../../../constants/mutations'
import { gql, useLazyQuery, useMutation } from '@apollo/client';
// Machine
import Alerts from '../../../../components/common/Alerts'
import { GlobalStateContext } from '../../../global.state'
import { useActor } from '@xstate/react'

const COURSE_INFO_QUERY = gql`
  query courseInfo($courseId: Int) {
    courseInfo(courseId: $courseId) {
      detail {
        code,
        name,
        servicesOfferedLabel,
        price,
        description,
        maxExamAttempts,
      },
      functions {
        id,
        name,
        items {
          name
          activeQuestions
          retiredQuestions
        },
        exams {
          id,
          name,
          questionsRetiredCount,
          questionsVisibleCount
        }
      }
    }
  }
`



const RetiredQuestionsChip = styled(Chip)(({theme, ...props}) => ({

}))
const ActiveQuestionsChip = styled(Chip)(({
  theme
}) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.success.main
}))

const useStyles = makeStyles((theme) => createStyles({
}))


function ConfirmImport ({
  open,
  filename,
  onDiscard,
  onConfirm
}) {

  return (
    <Dialog
      open={open}
      onClose={onDiscard}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please confirm to process importation of course <b>{filename}</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDiscard} color="secondary">
          Nope, not ready
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Yes, import
        </Button>
      </DialogActions>
    </Dialog>
  )
}
ConfirmImport.propTypes = {
  open: PropTypes.bool,
  filename: PropTypes.string.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}
ConfirmImport.defaultProps = {
  open: false,
  filename: ''
}

function CoursePage({

}) {
  const fileSelector = createRef()
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()
  const params = useParams();
  const Services = useContext(GlobalStateContext)
  const CourseState = useActor(Services.CourseService)

  // console.log(params)
  const [getCourseInfo, {loading, error, data}] = useLazyQuery(COURSE_INFO_QUERY, {
    variables: {
      courseId: Number(params.courseId)
    },
    onCompleted(data) {
      CourseState[1]({type: 'SUCCESS', data: data.courseInfo})
    },
    onError(err) {
      CourseState[1]('FAILURE')
    }
  })
  const [importCourseQuestions, {}] = useMutation(IMPORT_COURSE, {
    onCompleted(data) {
      // console.log('success')
      CourseState[1]('SUCCESS')
    },
    onError(err) {
      // console.log('error')
      CourseState[1]('FAILED')
    }
  })
  const retry = () => {
    CourseState[1]('RETRY')
    getCourseInfo()
  }
  const handleFileSelect = () => {
    // console.log('fileSelector', fileSelector)
    fileSelector.current.click()
  }
  const handleFileChange = (event) => {
    const files = event.target.files
    if(files.length > 0) {
      CourseState[1]({type: 'IMPORT', data: files[0]})
    }
  }
  const showContent = () => {
    const functions = CourseState[0].context.functions
    if(CourseState[0].matches('fetch.process')) {
      return (
        <Box style={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div>
            <Typography align="center"><CircularProgress color="inherit"/></Typography>
            <Typography align="center">Please wait. . .</Typography>
          </div>
        </Box>
      )
    }
    if(CourseState[0].matches('fetch.failure')) {
      return (
        <Box style={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div>
            <Typography align="center"><ErrorIcon style={{fontSize: '3em'}} color="secondary"/></Typography>
            <Typography align="center">Failed to fetch course detail!</Typography>
            <Typography align="center">
              <Button color="secondary" onClick={() => retry()}>
                Click to retry
              </Button>
            </Typography>
          </div>
        </Box>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" style={{padding: theme.spacing(1), display: 'flex', justifyContent: 'space-between'}}>
            <span>{CourseState[0].context.info?.code} - {CourseState[0].context.info?.name}</span>
            <Button variant="contained" color="primary" onClick={() => handleFileSelect()}>IMPORT</Button>
          </Typography>
          {
            Boolean(CourseState[0].context.info?.servicesOfferedLabel) &&
            <Typography><Chip size="small" label={CourseState[0].context.info?.servicesOfferedLabel}/></Typography>
          }
          {
            Boolean(CourseState[0].context.info?.description) &&
            <Typography style={{padding: theme.spacing(1)}}>{CourseState[0].context.info?.description}</Typography>
          }
        </Grid>
        <Grid item xs={12}>
          <Table size="small" aria-label="functions and its competense table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right" style={{minWidth: 140}}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (() => {
                  if(functions.length == 0 && CourseState[0].matches('import.process')) {
                    return (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography color="primary" align="center">Importing questions. . .</Typography>
                        </TableCell>
                      </TableRow>
                    )
                  }
                  if(functions.length == 0) {
                    return (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography color="secondary" align="center">There are no course category yet.</Typography>
                        </TableCell>
                      </TableRow>
                    )
                  }

                  return functions.map((func) => (
                    <>
                      <TableRow key={'function-row-'+func.id}>
                        <TableCell component="th" scope="row">
                          <Typography>{func.name}</Typography>
                          <RetiredQuestionsChip size="small" label={'('+func.exams[0].questionsRetiredCount+') Retired Questions'}/>
                          &nbsp;
                          <ActiveQuestionsChip size="small" label={'('+func.exams[0].questionsVisibleCount+') Active Questions'}/>
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="View questions">
                            <IconButton onClick={() => history.push('/dev/course/function/'+func.id)}>
                              <ListIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      {
                        func.items.map((compentence) => (
                          <TableRow key={'competence-row-'+compentence.id}>
                            <TableCell component="th" scope="row">
                              <div>
                                <Typography style={{paddingLeft: theme.spacing(2)}}>{compentence.name}</Typography>
                              </div>
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title="Retired questions">
                                <RetiredQuestionsChip label={compentence.retiredQuestions}/>
                              </Tooltip>
                              <Tooltip title="Active questions">
                                <ActiveQuestionsChip label={compentence.activeQuestions}/>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </>
                  ))
                })()
              }
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    )
  }
  useEffect(() => {
    if(['import.failure', 'import.success'].some(CourseState[0].matches)) {
      fileSelector.current.value = ''
    }
    if(CourseState[0].matches('import.process')) {
      importCourseQuestions({
        variables: {
          courseId: Number(params.courseId),
          file: CourseState[0].context.file
        }
      })
    }
    if(CourseState[0].matches('import.success')) {
      CourseState[1]('FETCH')
      getCourseInfo()
    }
  }, [CourseState[0]])
  useEffect(() => {
    CourseState[1]('FETCH')
    getCourseInfo()
  }, [])
  return (
    <Paper elevation={3} style={{width: '100%', padding: theme.spacing(1), margin: theme.spacing(2)}}>
      <input
        style={{display: 'none'}}
        type="file"
        accept=".csv"
        ref={fileSelector}
        onChange={handleFileChange}
      />
      <ConfirmImport
        open={CourseState[0].matches('import.confirm')}
        filename={CourseState[0].context.file?.name}
        onDiscard={() => CourseState[1]('DISCARD')}
        onConfirm={() => CourseState[1]('CONFIRM')}
      />
      <Alerts.Loading
        open={CourseState[0].matches('import.process')}
        message="Importing . . ."
      />
      <Alerts.Failure
        open={CourseState[0].matches('import.failure')}
        message="Failed to import!"
        onClose={() => CourseState[1]('CLOSE')}
      />
      { showContent() }
    </Paper>
  )
}

CoursePage.propTypes = {

}

export default CoursePage