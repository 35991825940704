import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';

import { COURSE_STATUS as STATUS } from '@nereus/shared/constants';
import { COURSE_APPROVALS as QUERY } from 'constants/queries';
import { getApiUrl, displayDate as d } from 'utils';
import ApproveButton from './ApproveButton';
import DenyButton from './DenyButton';
import DenyModal from './DenyModal';
import Table from 'components/common/Table';
import Loading from 'components/common/Loading';
import { useQuery } from 'utils';

// Will be done just backend for now:
// const sortStatus = x => {
//   switch (x) {
//     case STATUS.pendingApproval: return 0;
//     case STATUS.rejected: return 1;
//     case STATUS.accepted: return 2;
//     default: return 3;
//   }
// }

const formatStatus = x => {
  switch (x) {
    case STATUS.pendingApproval: return 'Pending';
    case STATUS.rejected: return 'Rejected';
    case STATUS.accepted: return 'Accepted';
    default: return '?';
  }
}

const CourseApprovals = ({
  queryVariables,
  hideActions,
  hideStatus,
  hideAppliedOn,
  showApprovedDeniedOn,
  showApproverDenierMessage,
  showPaymentPostedOn,
  approvedDeniedOnLabel,
  approverDenierMessageLabel,
  disablePagination,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, LoadingProps } = useQuery(QUERY, {
    variables: queryVariables,
    enqueueSnackbar,
    errorMessage: `Error loading course approvals`,
  });
  const rows = get(data, 'courseApprovals', []);

  const [idToReject, setIdToReject] = useState(null);

  const openModal = id => setIdToReject(id);
  const closeModal = () => setIdToReject(null);

  return (
    <Loading {...LoadingProps}>
      { !hideActions && <DenyModal id={idToReject} closeModal={closeModal} /> }

      <Table
        rows={rows}
        columns={[
          { label: 'Username', get: row => row.user.username },
          { label: 'Course Code', get: row => row.course.code },
          { field: 'receiptAmountpaid', label: 'Amount' },
          { label: 'Status', hide: hideStatus, get: row => row.status, format: formatStatus },
          { label: 'Receipt', hide: hideActions, render: row => (
            <Button onClick={() => window.open(`${getApiUrl()}/${row.receiptPath}`, '_blank')}>
              view
            </Button>
          )},
          { field: 'receiptComment', label: 'Comment' },
          { field: 'appliedOn', hide: hideAppliedOn, format: d },
          { field: 'approvedDeniedOn', label: approvedDeniedOnLabel, hide: !showApprovedDeniedOn, format: d },
          { field: 'approverDenierMessage', label: approverDenierMessageLabel, hide: !showApproverDenierMessage },
          { field: 'paymentPostedOn', label: 'Posted On', hide: !showPaymentPostedOn, format: d },
          { label: 'Actions', hide: hideActions, render: row => {
            if (row.status !== STATUS.pendingApproval) return null;
            
            return (<>
              <ApproveButton id={Number(row.id)} />
              <DenyButton id={Number(row.id)} openModal={openModal} />
            </>)
          }},
        ]}
        disablePagination={disablePagination}
        {...rest}
      />
    </Loading>
  )
}

CourseApprovals.propTypes = {
  queryVariables: PropTypes.object,
  hideActions: PropTypes.bool,
  hideStatus: PropTypes.bool,
  hideAppliedOn: PropTypes.bool,
  showApprovedDeniedOn: PropTypes.bool,
  showApproverDenierMessage: PropTypes.bool,
  showPaymentPostedOn: PropTypes.bool,
  approvedDeniedOnLabel: PropTypes.string,
  approverDenierMessageLabel: PropTypes.string,
  disablePagination: PropTypes.bool,
}

CourseApprovals.defaultProps = {
  hideActions: false,
  hideStatus: false,
  hideAppliedOn: false,
  showApprovedDeniedOn: false,
  showApproverDenierMessage: false,
  showPaymentPostedOn: false,
  approvedDeniedOnLabel: 'Approved/Denied On',
  approverDenierMessageLabel: 'Approved/Denied Reason:',
  disablePagination: false,
}

export default memo(CourseApprovals);
