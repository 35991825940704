import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Material
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// Icons
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
// Validator
import { useFormik } from 'formik'
import * as yup from 'yup'

const useStyles = makeStyles((theme) => createStyles({
  successBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main
  }
}))

function UpdateFormDialog({
  open,
  data,
  onClose,
  onSave
}) {
  const classes = useStyles()
  const formik = useFormik({
    initialValues: {
      text: '',
      choices: [],
      isRetired: false
    },
    validationSchema: yup.object({
      text: yup.string()
        .required('The text field is required.'),
      choices: yup.array()
        .of(yup.object().shape({
          text: yup.string()
            .required('The choice text is required.')
        }))
      // isRetired
    }),
    onSubmit(values) {
      formik.setSubmitting(false)
      onSave(values)
    }
  })
  const handleChangeCorrect = (choice) => {
    let choices = [...formik.values.choices]
    choices = choices.map(item => {
      let c = {...item}
      if(choice.id == c.id) {
        c.isCorrect = true
      } else {
        c.isCorrect = false
      }
      return c
    })

    formik.setFieldValue('choices', choices, false)
  }
  const handleIsRetired = () => {
    formik.setFieldValue('isRetired', !formik.values.isRetired, false);
  }
  const handleChoiceText = (event, choice) => {
    let choices = [...formik.values.choices]
    for(let i=0; i < choices.length; i++) {
      let c = {...choices[i]}
      if(c.id == choice.id) {
        c.text = event.target.value
        choices[i]=c
        break
      }
    }
    formik.setFieldValue('choices', choices, false)
  }
  const handleTextChange = (event) => {
    formik.setFieldValue('text', event.target.values, false)
  }
  useEffect(() => {
    if(Boolean(data)) {
      formik.resetForm({
        values: {
          text: data?.text,
          choices: data?.choices,
          isRetired: data?.isRetired
        }
      })
    }
  }, [open])
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="question-form-dialog-title">
      <DialogTitle id="question-form-dialog-title">Update question</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus={true}
              fullWidth
              variant="outlined"
              multiline={true}
              rows={4}
              label="Text *"
              value={formik.values.text}
              onChange={(event) => handleTextChange(event)}
              error={Boolean(formik.errors.text)}
              helperText={Boolean(formik.errors.text) && formik.errors.text}
            />
          </Grid>
          {
            formik.values.choices.map((choice, index) => (
              <Grid item xs={12} container spacing={2} key={'choice-item-'+choice.id}>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Choice"
                    value={choice.text}
                    onChange={(event) => handleChoiceText(event, choice)}
                    error={Boolean(((formik.errors.choices || [])[index]||{})?.text)}
                    helperText={Boolean(((formik.errors.choices || [])[index]||{})?.text) && ((formik.errors.choices || [])[index]||{})?.text}
                  />
                </Grid>
                <Grid item xs={2}>
                  {
                    (() => {
                      if(choice.isCorrect) {
                        return <Button className={classes.successBtn} variant="contained" fullWidth>
                          <CheckIcon />
                        </Button>
                      }
                      return <Button color="secondary" variant="contained" fullWidth onClick={() => handleChangeCorrect(choice)}>
                        <CloseIcon />
                      </Button>
                    })()
                  }
                </Grid>
              </Grid>
            ))
          }
          <Grid item xs={12}>
            <FormControlLabel control={<Checkbox color="primary" checked={formik.values.isRetired} onChange={() => handleIsRetired()}/>} label="Is retired?" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={onClose} color="secondary">
          Close
        </Button>
        <Button disabled={formik.isSubmitting} onClick={formik.handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UpdateFormDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}

export default UpdateFormDialog