import React, {  } from 'react';
// Material
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';

const ExamPageFetching = () => {
  const theme = useTheme()
  return (
    <Paper elevation={6} style={{padding: theme.spacing(3)}}>
      <Typography align="center"><CircularProgress /></Typography>
      <Typography align="center">Begin exam. . .</Typography>
    </Paper>
  )
}

export default ExamPageFetching;
