/* global window */
import React, { memo, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get, noop, filter, map, sort } from 'lodash';
import { useSnackbar } from 'notistack';

import { EXAM_TYPE } from '@nereus/shared/constants';
import Exam from 'components/common/Exam';
import Loading from 'components/common/Loading';
import { BEGIN_EXAMV2, ANSWER_EXAM } from 'constants/mutations';
import { checkIfExamCheatsEnabled } from 'reduxConfig/selectors';
// Material
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
// import { useMutation, performChecking } from 'utils';
import { gql, useQuery, useMutation } from '@apollo/client';
// Machine
import { GlobalStateContext } from '../global.state'
import { useActor } from '@xstate/react'
// Components
import ExamPageFetching from './ExamPage.Fetching'
import ExamPageFailure from './ExamPage.Failure'
import ExamPageQuestions from './ExamPage.Questions'
import ExamPageResult from './ExamPage.Result'

const ExamPage = () => {
  const theme = useTheme()
  const Services = useContext(GlobalStateContext)
  const ExamState = useActor(Services.ExamService)
  const exam = ExamState[0].context.exam
  const examFunction = get(exam, 'exam', {})
  const questions = window._.orderBy(get(exam, 'qas', []), [
    (o) => {
      const competenceNumber = get(o, 'question.courseItem.name', '').match(/\d+/)
      return parseInt(competenceNumber)
    }
  ], ['asc'])
  const errorMessage = get(ExamState[0].context, 'errorMessage', 'Failed to begin exam')
  const result = get(ExamState[0].context, 'result')

  const history = useHistory();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const examCheatsEnabled = useSelector(checkIfExamCheatsEnabled);
  //^admins get the correct answers in the response
  // so we can enable live checking for them
  const examId = Number(params.examId);
  const [beginExam] = useMutation(BEGIN_EXAMV2(examCheatsEnabled), {
    variables: { examId },
    onCompleted(data, clientOptions) {
      // console.log(data)
      ExamState[1]({type: 'SUCCESS', data: data.beginExam})
    },
    onError(error, clientOptions) {
      // console.log(error.message)
      ExamState[1]({type: 'FAILURE', data: {message: error.message}})
    }
  })
  const [answerExam] = useMutation(ANSWER_EXAM, {
    onCompleted(data, clientOptions) {
      // console.log(data)
      ExamState[1]({type: 'SUCCESS', data: data.answerExam})
    },
    onError(error, clientOptions) {
      // console.log(error)
      ExamState[1]({type: 'FAILURE', error})
    }
  })

  // const [beginExam, { loading, error, data }] = useMutation(BEGIN_EXAM,
  // {
  //   enqueueSnackbar,
  //   successMessage: null,
  //   errorMessage: 'Error starting exam',
  //   variables: { examId },
  //   onError: (e, g) => {
  //     g(e);
  //     history.push(`/`);
  //   },
  // });
  // useEffect(() => {
  //   beginExam();
  //   return noop;
  // }, [beginExam]);

  // <Exam /> handles success/errors in this case:
  // const [answerExam] = useMutation(ANSWER_EXAM, { autoSuccessErrorMessages: false });

  // const examName = get(data, 'beginExam.exam.name', 'Exam');
  // const qas = get(data, 'beginExam.qas', []);
  // const questions = qas.map(qa => ({
  //   ...qa.question,
  //   id: qa.id,
  // }));

  // const handleSubmit = async ({ userQA = {} }) => {
  //   const answers = Object.entries(userQA)
  //     .map(([qasId, choiceId]) =>
  //       ({ qasId: Number(qasId), choiceId: Number(choiceId) }));
    
  //   const response = await answerExam({ variables: { examId, answers }});

  //   return get(response, 'data.answerExam.score', 0);
  // }
  const handleRetry = () => {
    ExamState[1]('RETRY')
    beginExam()
  }
  const fetchExam = () => {
    ExamState[1]('BEGIN_EXAM')
    beginExam()
  }
  const handleSubmit = (answers) => {
    // console.log('answers', answers)
    const questionsWithChoices = filter(answers, (o) => Boolean(o.choiceId))
    // console.log('questionsWithChoices', questionsWithChoices)
    const mapped = map(questionsWithChoices, (o) => ({qasId: Number(o.userQAId), choiceId: Number(o.choiceId), timeSpent: o.timeSpent}))
    // console.log('mapped', mapped)
    answerExam({
      variables: {
        examId: examId,
        answers: mapped
      }
    })
    // setTimeout(() => {
    //   ExamState[1]('FAILURE')
    // }, 2000)
  }
  const handleOnExamList = () => {
    history.replace('/exam-list')
  }

  useEffect(() => {
    ExamState[1]('INACTIVE')
    fetchExam()
  }, [])
  return (
    <Grid container spacing={2} style={{padding: theme.spacing(3)}}>
      {
        (() => {
          if(ExamState[0].matches('begin.process')) {
            return (
              <Grid item xs={12}>
                <ExamPageFetching />
              </Grid>
            )
          }
          if(ExamState[0].matches('begin.failure')) {
            return (
              <Grid item xs={12}>
                <ExamPageFailure
                  message={errorMessage || 'Failed to begin exam'}
                  onRetry={handleRetry}
                  onExamList={handleOnExamList}
                />
              </Grid>
            )
          }
          if(ExamState[0].matches('submit.success')) {
            return (
              <ExamPageResult
                exam={exam}
                result={result}
                totalQuestions={questions.length}
              />
            )
          }
          return (
            <Grid item xs={12}>
              <ExamPageQuestions
                cheatEnable={examCheatsEnabled}
                onSubmit={handleSubmit}
                userExamId={get(exam, 'id', null)}
                machine={ExamState}
                exam={examFunction}
                questions={questions}
              />
            </Grid>
          )
        })()
      }
    </Grid>
  )
}

export default memo(ExamPage);
