import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import {
  Box,
  Grid,
  Typography,
  RadioGroup,
} from '@material-ui/core';
import Choice from './Choice';

const ExamQuestion = ({
  id,
  text,
  choices,
  questionNumber,
  handleChangeRadio,
  currentAnswer,
  ...rest
}) => {
  return (
    <Grid container item>
      <Grid container>
        <Typography>
          <Box component='span' fontWeight='fontWeightBold'>
            {questionNumber})&nbsp;
          </Box>
          
          <Box component='span'>{text}</Box>
        </Typography>
      </Grid>
      
      <Grid container>
        <RadioGroup row value={currentAnswer} className="px-3" onChange={e => handleChangeRadio(id, e)}>
          {choices.map(({ id, text, isCorrect }) => (
            <Choice
              key={id}
              {...rest}
              id={id}
              text={text}
              currentAnswer={currentAnswer}
              isThisChoiceCorrect={isCorrect}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  )
}

ExamQuestion.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  text: PropTypes.string.isRequired,
  choices: PropTypes.array.isRequired,
  questionNumber: PropTypes.number.isRequired,
  handleChangeRadio: PropTypes.func.isRequired,
  currentAnswer: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
}

ExamQuestion.defaultProps = {
  choices: [],
  handleChangeRadio: noop,
}

export default memo(ExamQuestion);
