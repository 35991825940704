import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { EXAM_TYPE } from '@nereus/shared/constants';
import { BEGIN_EXAM_REVIEW as QUERY } from 'constants/queries';
import Page from './_TrialExamOrReviewPage';

const ReviewPage = () => {
  const params = useParams();
  const courseItemId = Number(params.courseItemId);
  console.log('asd'+courseItemId);
  return (
    <Page
      examKey={`${EXAM_TYPE.review}_${courseItemId}`}
      query={QUERY}
      queryVariables={{ courseItemId }}
      queryName='beginExamReview'
      performLiveChecking={true}
    />
  );
}

export default memo(ReviewPage);
