import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { get, find, findIndex, filter } from 'lodash';
import moment from 'moment'
import useKeypress from 'react-use-keypress';
// Material
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Pagination from '@material-ui/lab/Pagination';
import { useTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// GraphQL
import { EXAM_TIME_SPENT } from 'constants/mutations';
import { gql, useQuery, useMutation } from '@apollo/client';
// Icons
import ErrorIcon from '@material-ui/icons/Error';
// Validation
import { useFormik } from 'formik'
import * as yup from 'yup'
// Components
import ExamPageQuestionsConfirm from './ExamPage.Questions.Confirm'
import ExamPageQuestionsNotAllAnswered from './ExamPage.Questions.NotAllAnswered'

const ChoiceCorrect = withStyles(({palette, spacing}) => ({
  root: {
    color: palette.success.main,
    '& .Mui-checked': {
      color: palette.success.main,
    },
  }
}))((props) => <FormControlLabel color="success" {...props}/>)

const ChoiceWrong = withStyles(({palette, spacing}) => ({
  root: {
    color: palette.error.main,
    '& .Mui-checked': {
      color: palette.error.main,
    },
  }
}))((props) => <FormControlLabel color="error" {...props}/>)

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}))

const DEFAULT_VALUES = {
  questions: []
}

const ExamPageQuestions = ({
  onSubmit,
  userExamId,
  exam,
  questions,
  machine,
  cheatEnable
}) => {
  const classes = useStyles()
  const theme = useTheme()
  // const currentTimeSpent = get(machine[0].context.exam, 'timeSpent', '00:00:00') || '00:00:00'
  const currentTimeSpent = '00:00:00'
  const question = get(machine[0].context, 'question', {})
  const page = get(machine[0].context, 'page', 1)
  const date = moment();
  date.seconds(0)
  date.minute(0)
  date.hour(0)
  const [startTime, setStartTime] = useState(null);
  const [count, setCount] = useState(0);
  const form = useFormik({
    initialValues: {...DEFAULT_VALUES},
    validationSchema: yup.object({
      questions: yup.array()
        .required('Please answer your exam!')
        .of(yup.object({
          userQAId: yup.string().required('user exam id is required.'),
          questionId: yup.string().required('question id id is required.'),
          // choiceId: yup.string().required('answer id is required.'),
          // timeSpent: yup.string().required('time spent is required.'),
        }))
    })
  })
  const [setExamTimeSpend, {data, loading, error}] = useMutation(EXAM_TIME_SPENT, {
    variables: { userExamId },
    onCompleted(data, clientOptions) {

    },
    onError(error, clientOptions) {

    }
  })
  const handleChoice = (e, choiceId, q = null) => {
    const currentQuestion = Boolean(q) ?q :{...question}
    // console.log('currentQuestion', currentQuestion)
    const formQuestions = [...form.values.questions]
    const formQuestionIndex = findIndex(formQuestions, {userQAId: get(currentQuestion, 'id', null)})
    if(formQuestionIndex > -1) {
      const formQuestion = {...formQuestions[formQuestionIndex], choiceId}
      // console.log('handleChoic', formQuestion)
      formQuestions[formQuestionIndex] = formQuestion
    } else {
      formQuestions.push({
        userQAId: get(currentQuestion, 'id'),
        questionId: get(currentQuestion, 'question.id'),
        choiceId,
        timeSpent: 0
      })
    }
    form.setFieldValue('questions', formQuestions)
  }
  const updateTimeSpent = (q) => {
    const prevQuestion = {...q}
    // console.log('prevQuestion', prevQuestion)
    const startTime = moment(get(prevQuestion, 'startTime', new Date()))
    const endTime = moment()
    
    // console.log('timeSpent', timeSpent)
    const formQuestions = [...form.values.questions]
    const formQuestionIndex = findIndex(formQuestions, {userQAId: get(prevQuestion, 'id', null)})
    if(formQuestionIndex > -1) {
      // console.log('question exists')
      const formQuestion = {...formQuestions[formQuestionIndex]}
      let timeSpent = endTime.diff(startTime, 'minutes', true).toString()
      timeSpent = timeSpent.match(/\d+\.\d{2}/)
      // console.log('timeSpent', timeSpent)
      // console.log('formQuestion.timeSpent', formQuestion.timeSpent)
      timeSpent = parseFloat(parseFloat(formQuestion.timeSpent) + parseFloat(timeSpent))
      // console.log('Form timespent', formQuestion.timeSpent)
      // console.log('Total time spend', timeSpent)
      formQuestion.timeSpent = timeSpent
      // console.log('updateTimeSpent', formQuestion)
      formQuestions[formQuestionIndex]=formQuestion
    }
    form.setFieldValue('questions', formQuestions)
  }
  const handlePageChange = (event, newPage) => {
    // machine[1]()
    updateTimeSpent({...question})
    machine[1]({type: 'QUESTION', data: questions[newPage-1]})
    machine[1]({type: 'PAGE', data: newPage})
  }
  const isSelected = ( choiceId ) => {
    const currentQAId = get(question, 'id', null)
    const formQuestion = find(form.values.questions, (o) => get(o, 'userQAId') == currentQAId, null)
    if(Boolean(formQuestion)) {
      if(get(formQuestion, 'userQAId') == currentQAId) {
        if(get(formQuestion, 'choiceId') == choiceId) {
          return true
        }
      }
    }
    return false
  }
  const incrementTime = () => {
    setCount(() => count + 1)
    setTimeout(() => {
      const time = moment(startTime)
      time.add(1, 's')
      setStartTime(time)
    }, 1000)
  }
  const handleSubmit = () => {
    
    const formQuestions = [...form.values.questions]
    const answeredPrayer = filter(formQuestions, (o) => Boolean(o.choiceId)).length
    console.log('answeredPrayer', answeredPrayer)
    console.log('answeredPrayer', answeredPrayer, questions.length)
    if(answeredPrayer == 0) {
      machine[1]('INVALID')
    } else if (answeredPrayer < questions.length ) {
      machine[1]('NOT_ALL_ANSWERED')
    } else {
      machine[1]('SUBMIT')
    }
  }
  const handleConfirm = () => {
    machine[1]('CONFIRM')
    onSubmit(form.values.questions)
  }
  useEffect(() => {
    // Set initial questions
    const question = {...questions[page-1]}
    // console.log('currentTimeSpent', currentTimeSpent)
    const timeSplit = currentTimeSpent.split(':')
    const date = moment().set({
      hour: parseInt(timeSplit[0]),
      minute: parseInt(timeSplit[1]),
      second: parseInt(timeSplit[2])
    }) 
    setStartTime(date)
    machine[1]({type: 'QUESTION', data: {...question}})
    
  }, [])
  useEffect(() => {
    if(count >= 5) {
      setCount(0)
      const time = startTime.format('HH:mm:ss')
      setExamTimeSpend({
        variables: {
          userExamId: parseInt(userExamId),
          timeSpent: time
        }
      })
    }
  }, [count])
  useEffect(() => {
    // console.log('startTime')
    if(Boolean(startTime)) {
      incrementTime()
    }
  }, [startTime])
  useEffect(() => {
    if(Boolean(question) && Object.keys(question).length > 0) {
      // console.log('New assigned question', {...question})
      handleChoice(null, '', {...question, startTime: new Date()})
    }
  }, [question])
  useKeypress(['ArrowLeft', 'ArrowRight'], (event) => {
    if (event.key === 'ArrowLeft') {
      const newPage = page - 1
      handlePageChange(null, newPage < 1 ?1 :newPage);
    } else if (event.key === 'ArrowRight') {
      const newPage = page + 1
      handlePageChange(null, newPage > questions.length ?questions.length :newPage);
    }
  });
  return (
    <Paper elevation={6}>
      <ExamPageQuestionsConfirm
        open={machine[0].matches('submit.confirm')}
        onConfirm={handleConfirm}
        onCancel={() => machine[1]('CANCEL')}
      />
      <ExamPageQuestionsNotAllAnswered
        open={machine[0].matches('submit.notAllAnswered')}
        onConfirm={handleConfirm}
        onCancel={() => machine[1]('CANCEL')}
      />
      <Backdrop open={machine[0].matches('submit.process')} className={classes.backdrop}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography align="center"><CircularProgress color="inherit" /></Typography>
          <Typography align="center">Checking, please wait. . .</Typography>
        </Box>
      </Backdrop>
      <Snackbar open={machine[0].matches('submit.invalid')} anchorOrigin={{vertical:'bottom', horizontal: 'right'}} autoHideDuration={6000} onClose={() => machine[1]('CLOSE')}>
        <MuiAlert onClose={() => machine[1]('CLOSE')} severity="error" elevation={6} variant="filled">
          Please complete your exam.
        </MuiAlert>
      </Snackbar>
      <Snackbar open={machine[0].matches('submit.failure')} anchorOrigin={{vertical:'bottom', horizontal: 'right'}} autoHideDuration={6000} onClose={() => machine[1]('CLOSE')}>
        <MuiAlert onClose={() => machine[1]('CLOSE')} severity="error" elevation={6} variant="filled">
          Failure to submit answers. Try again.
        </MuiAlert>
      </Snackbar>
      <Grid container spacing={2} style={{margin: 0, width: '100%'}}>
        <Grid item xs={8} style={{padding: theme.spacing(3), backgroundColor: theme.palette.primary.main, color: theme.palette.common.white}}>
          <Typography variant="h4">
            { get(exam, 'courseCategory.course.name') }
          </Typography>
          <Typography variant="h5">
            { get(exam, 'name') }
          </Typography>
          <Typography variant="h5">
            { get(question, 'question.courseItem.name', '') }
          </Typography>
        </Grid>
        <Grid item xs={4} style={{padding: theme.spacing(3), backgroundColor: theme.palette.primary.main, display: 'flex', alignItems: 'end'}}>
          <Box style={{backgroundColor: theme.palette.common.white, width: '100%', border: '5px solid '+theme.palette.error.dark}}>
            <Typography align="center" variant="h2">{Boolean(startTime) ?startTime.format('HH:mm:ss') :'00:00:00'}</Typography>
          </Box>
        </Grid>
        <Grid container spacing={0} item xs={12} style={{padding: theme.spacing(3), margin: 0, width: '100%'}}>
          <Grid item xs={12}>
            <Typography variant="h3">{page}. {get(question, 'question.text')}</Typography>
          </Grid>
          <Grid item xs={12} style={{paddingTop: theme.spacing(3)}}>
            <FormGroup>
              {
                get(question, 'question.choices', []).map((choice, index) => {
                  if(cheatEnable) {
                    if(choice.isCorrect) {
                      return (
                        <ChoiceCorrect
                          key={'choice-'+choice.id}
                          control={<Checkbox checked={isSelected(get(choice, 'id', null))} onChange={(e) => handleChoice(e, get(choice, 'id', null))} />}
                          label={choice.text}
                        />
                      )
                    }
                    return (
                      <ChoiceWrong
                        key={'choice-'+choice.id}
                        control={<Checkbox checked={isSelected(get(choice, 'id', null))} onChange={(e) => handleChoice(e, get(choice, 'id', null))} />}
                        label={choice.text}
                      />
                    )
                  }
                  return (
                    <FormControlLabel
                      key={'choice-'+choice.id}
                      control={<Checkbox color="primary" checked={isSelected(get(choice, 'id', null))} onChange={(e) => handleChoice(e, get(choice, 'id', null))} />}
                      label={choice.text}
                    />
                  ) 
                })
              }
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <span></span>
            <Pagination color="primary" page={page} count={questions.length} onChange={handlePageChange}/>
            {
              (() => {
                if(page == questions.length) {
                  return (
                    <Button variant="contained" color="primary" size="large" onClick={handleSubmit}>SUBMIT</Button>
                  )
                }

                return (
                  <span></span>
                )
              })()
            }
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
ExamPageQuestions.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  userExamId: PropTypes.string.isRequired,
  exam: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  machine: PropTypes.array.isRequired,
  cheatEnable: PropTypes.bool.isRequired
}

export default ExamPageQuestions;
