import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom';
import { highlight, truncate } from '../../../../utils'
// Materials
import { makeStyles, useTheme, styled, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Chip from '@material-ui/core/Chip';
import TablePagination from '@material-ui/core/TablePagination';
import Pagination from '@material-ui/lab/Pagination';
// Icons
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
// Relative components
import Alerts from '../../../common/Alerts'
import UpdateFormDialog from './UpdateFormDialog'
import RetireDialog from './RetireDialog'
// GraphQL
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FUNCTION_INFO_AND_COMPETENCES } from '../../../../constants/queries'
import { UPDATE_QUESTION, RETIRE_QUESTION } from '../../../../constants/mutations'
// Context
import { GlobalStateContext } from '../../../global.state'
import { useActor } from '@xstate/react'

const useStyles = makeStyles((theme) => createStyles({
  iconError: {
    color: theme.palette.error.main
  },
  iconSuccess: {
    color: theme.palette.success.main
  },
  iconInfo: {
    color: theme.palette.info.main
  }
}))

function FunctionQuestionsPage({

}) {
  const classes = useStyles()
  const theme = useTheme()
  const params = useParams()
  const Services = useContext(GlobalStateContext)
  const FunctionsState = useActor(Services.FunctionsService)
  const competences = FunctionsState[0].context.competences
  const rowsPerPage = FunctionsState[0].context.rowsPerPage
  const totalQuestions = FunctionsState[0].context.totalQuestions
  const page = FunctionsState[0].context.page
  const detail = FunctionsState[0].context.detail
  const questions = FunctionsState[0].context.questions
  const filter = FunctionsState[0].context.filter
  const selectedQuestion = FunctionsState[0].context.selectedQuestion
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalQuestions - page * rowsPerPage);
  const emptyRows = rowsPerPage - questions.length;
  const debounce = useState(null)
  const keyword = useState('')
  const [updateQuestion, {}] = useMutation(UPDATE_QUESTION, {
    onCompleted(data) {
      FunctionsState[1]({type: 'SUCCESS', data: data.updateQuestion.question})
    },
    onError(err) {
      FunctionsState[1]('FAILURE')
    }
  })
  const [retireQuestion, {}] = useMutation(RETIRE_QUESTION, {
    onCompleted(data) {
      FunctionsState[1]({type: 'SUCCESS', data: data.retireQuestion.question})
    },
    onError(err) {
      FunctionsState[1]('FAILURE')
    }
  })
  const [getFunctionInfo, {loading, error, data}] = useLazyQuery(FUNCTION_INFO_AND_COMPETENCES, {
    variables: {
      rowsPerPage,
      page,
      functionId: Number(params.functionId),
    },
    onCompleted(data) {
      FunctionsState[1]({type: 'SUCCESS', data: data.functionInfoAndCompetences})
    },
    onError(err) {
      FunctionsState[1]('FAILURE')
    }
  })
  const handleRetireQuestion = () => {
    FunctionsState[1]('CONTINUE')
    retireQuestion({
      variables: {
        questionId: Number(selectedQuestion?.id)
      }
    })
  }
  const retry = () => {
    FunctionsState[1]('RETRY')
    getFunctionInfo()
  }
  const handleUpdate = (values) => {
    FunctionsState[1]({type: 'SAVE', data: values})
    const params = {
      id: Number(selectedQuestion?.id),
      isRetired: values.isRetired,
      text: values.text,
      choices: values.choices.map(c => ({id: parseInt(c.id), isCorrect: c.isCorrect, text: c.text}))
    }
    updateQuestion({variables: {question: params}})
    // setTimeout(() => {
    //   FunctionsState[1]('FAILURE')
    // }, 3000)
  }
  const handleOnChangeSearch = (event) => {
    const value = event.target.value
    keyword[1](value)
    if(Boolean(debounce[0])) {
      clearTimeout(debounce[0])
    }
    debounce[1](setTimeout(() => {
      FunctionsState[1]({type: 'SET_PAGE', data: 1})
      FunctionsState[1]({type: 'SET_FILTER', data: {search: value}})
      handleSearch()
    }, 300))
  };

  const handleSearch = () => {
    FunctionsState[1]('SEARCHING')
  }

  const handlePageChange = (event, newPage) => {
    FunctionsState[1]({type: 'SET_PAGE', data: newPage})
    handleSearch()
  }

  const showContent = () => {
    if(FunctionsState[0].matches('fetch.process')) {
      return (
        <Box style={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div>
            <Typography align="center"><CircularProgress color="inherit"/></Typography>
            <Typography align="center">Please wait. . .</Typography>
          </div>
        </Box>
      )
    }
    if(FunctionsState[0].matches('fetch.failure')) {
      return (
        <Box style={{height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <div>
            <Typography align="center"><ErrorIcon style={{fontSize: '3em'}} color="secondary"/></Typography>
            <Typography align="center">Failed to fetch course detail!</Typography>
            <Typography align="center">
              <Button color="secondary" onClick={() => retry()}>
                Click to retry
              </Button>
            </Typography>
          </div>
        </Box>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">{detail?.name}</Typography>
        </Grid>
        <Grid item xs={7}>
          <TextField
            size="small"
            fullWidth
            label="Search . . ."
            variant="outlined"
            value={keyword[0]}
            onChange={handleOnChangeSearch}
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel htmlFor="competence-select">Competence</InputLabel>
            <Select
              native
              label="Competence"
              value={filter.compentence}
              onChange={(event) => { FunctionsState[1]({type: 'SET_FILTER', data: {compentence: event.target.value}}); handleSearch();}}
              inputProps={{
                name: 'compentence',
                id: 'competence-select',
              }}
            >
              <option value=""></option>
              {
                competences.map((compentence) => (
                  <option key={'competence-option-'+compentence.id} value={compentence.id}>{compentence.name}</option>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel htmlFor="filter-select">Filter</InputLabel>
            <Select
              native
              label="Filter"
              value={filter.filter}
              onChange={(event) => { FunctionsState[1]({type: 'SET_FILTER', data: {filter: event.target.value}}); handleSearch(); }}
              inputProps={{
                name: 'filter',
                id: 'filter-select',
              }}
            >
              <option value=""></option>
              <option value="retired">Retired</option>
              <option value="active">Active</option>
            </Select>
          </FormControl>
        </Grid>
        {/*<Grid item xs={12}>
          <Pagination
            count={Math.ceil(totalQuestions/rowsPerPage)}
            color="primary"
          />
        </Grid>*/}
        <Grid item xs={12}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Question</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                (() => {
                  if(FunctionsState[0].context.questions.length == 0) {
                    return (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography align="center" style={{color: theme.palette.info.main, display: 'flex', justifyContent: 'center'}}><InfoIcon/> There are no questions yet.</Typography>
                        </TableCell>
                      </TableRow>
                    )
                  }
                  if(questions.length == 0) {
                    return (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography align="center" style={{color: theme.palette.info.main, display: 'flex', justifyContent: 'center'}}><InfoIcon/> There are no result. Please try other keyword!</Typography>
                        </TableCell>
                      </TableRow>
                    )
                  }
                  return (
                    questions
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => (
                      <TableRow key={'question-row'+row.id}>
                        <TableCell component="th" scope="row">
                          <Typography>
                            <Chip size="small" color="primary" label={truncate(row.courseItem?.name, 88)}/>
                          </Typography>
                          <Typography dangerouslySetInnerHTML={{__html: highlight(row.text, filter.search, '<span style="color: '+theme.palette.warning.main+'">', '</span>')}}></Typography>
                          <ul style={{listStyle: 'disc', paddingLeft: '15px'}}>
                          {
                            row.choices.map((choice, index) => (
                              <li key={'choice-item-'+index+'-'+row.id} dangerouslySetInnerHTML={{__html: highlight(choice.text, filter.search, '<span style="color: '+theme.palette.warning.main+'">', '</span>')}}></li>
                            ))
                          }
                          </ul>
                        </TableCell>
                        <TableCell align="right">
                          {
                            row.isRetired &&
                            <Tooltip title="Retired">
                              <IconButton>
                                <BlockIcon className={classes.iconError} />
                              </IconButton>
                            </Tooltip>
                          }
                          {
                            !row.isRetired &&
                            <Tooltip title="Active">
                              <IconButton onClick={() => FunctionsState[1]({type: 'RETIRE', data: row})}>
                                <CheckCircleIcon className={classes.iconSuccess} />
                              </IconButton>
                            </Tooltip>
                          }
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Edit">
                            <IconButton onClick={() => FunctionsState[1]({type: 'EDIT', data: row})}>
                              <EditIcon className={classes.iconInfo} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )
                })()
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: 195 * emptyRows }}>
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {/*<TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            component="div"
            count={totalQuestions}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(event, newPage) => FunctionsState[1]({type: 'SET_PAGE', data: newPage})}
            onRowsPerPageChange={(event) => FunctionsState[1]({type: 'SET_ROW_PER_PAGE', data: parseInt(event.target.value, 10)})}
          />*/}
        </Grid>
        <Grid item xs={12}>
          <Pagination
            page={page}
            style={{'float': 'right'}}
            count={Math.ceil(totalQuestions/rowsPerPage)}
            color="primary"
            onChange={handlePageChange}
          />
        </Grid>
      </Grid>
    )
  }
  useEffect(() => {
    // console.log(FunctionsState[0])
    if(FunctionsState[0].matches('fetch.success')) {
      FunctionsState[1]('CLOSE')
    }
    if(FunctionsState[0].matches('search.processing')) {
      getFunctionInfo({
        variables: {
          functionId: Number(params.functionId),
          ...filter,
          page
        }
      })
    }
  }, [FunctionsState[0]])
  useEffect(() => {
    FunctionsState[1]({type: 'SET_PAGE', data: 1})
    FunctionsState[1]('FETCH')
    getFunctionInfo()
  }, [])
  return (
    <Paper elevation={3} style={{width: '100%', padding: theme.spacing(1), margin: theme.spacing(2)}}>
      <RetireDialog
        open={FunctionsState[0].matches('retire.show')}
        onContinue={() => handleRetireQuestion()}
        onDiscard={() => FunctionsState[1]('CANCEL')}
      />
      <Alerts.Loading
        open={FunctionsState[0].matches('retire.process')}
        message="Retiring question. . ."
      />
      <Alerts.Success
        open={FunctionsState[0].matches('retire.success')}
        message="Question has been retired!"
        onClose={() => FunctionsState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={FunctionsState[0].matches('retire.failure')}
        message="Failed to retire question"
        onClose={() => FunctionsState[1]('CLOSE')}
      />

      <UpdateFormDialog
        open={FunctionsState[0].matches('edit.show')}
        data={selectedQuestion}
        onSave={(values) => handleUpdate(values)}
        onClose={() => FunctionsState[1]('CANCEL')}
      />
      <Alerts.Loading
        open={FunctionsState[0].matches('edit.process')}
        message="Updating question. . ."
      />
      <Alerts.Success
        open={FunctionsState[0].matches('edit.success')}
        message="Question updated!"
        onClose={() => FunctionsState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={FunctionsState[0].matches('edit.failure')}
        message="Failed to update question"
        onClose={() => FunctionsState[1]('CLOSE')}
      />
      { showContent() }
    </Paper>
  )
}

FunctionQuestionsPage.propTypes = {

}

export default FunctionQuestionsPage