import { createMachine, assign } from 'xstate'


export default createMachine({
  id: 'Review',
  initial: 'inactive',
  context: {
    page: 1,
    name: '',
    competence: null,
    questions: [],
    result: null
  },
  states: {
    inactive: {
      on: {
        FETCH: 'fetching'
      }
    },
    fetching: {
      on: {
        SUCCESS: {
          target: 'success',
          actions: ['setQuestionsAndName']
        },
        FAILURE: 'failure'
      }
    },
    success: {
      on: {
        FETCH: 'fetching',
        SET_PAGE: {
          actions: ['setPage']
        },
        SUBMIT: 'check.process'
      }
    },
    failure: {
      on: {
        RETRY: 'fetching'
      }
    },
    check: {
      states: {
        process: {
          on: {
            CHECK_SUCCESS: {
              target: '#Review.result',
              actions: ['setResult']
            },
            CHECK_FAILED: 'failure'
          }
        },
        // success: {},
        failure: {
          on: {
            CLOSE: '#Review.success',
            SUBMIT: 'process',
            SET_PAGE: {
              actions: ['setPage']
            },
          },
        },
      }
    },
    result: {
      on: {
        FETCH: 'fetching'
      }
    }
  }
}, {
  actions: {
    setResult: assign({
      result(ctx, event) {
        return event.data
      }
    }),
    setPage: assign({
      page(ctx, event) {
        return event.data
      }
    }),
    setQuestionsAndName: assign({
      page: 1,
      name(ctx, event) {
        return event.data.name
      },
      competence(ctx, event) {
        return event.data.competence
      },
      questions(ctx, event) {
        return window._.shuffle(event.data.questions)
      }
    })
  }
})