import React, {} from 'react'
import PropTypes from 'prop-types'
// Material
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


function ExamPageQuestionsConfirm ({
  open,
  onConfirm,
  onCancel
}) {

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm to submit your answer?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Before to submit answers please make sure all questios are answered.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Yes Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
ExamPageQuestionsConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default ExamPageQuestionsConfirm
