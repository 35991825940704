import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Formik,
  Form as FormikForm,
} from 'formik';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: props => props.containerPadding || 16*2,
  },
  textRoot: {
    paddingBottom: 8*4,
  },
  buttonsContainer: {
    marginTop: 8*4,
  },
  button: {
    minHeight: 8*8,
    marginTop: 8,
  },
}));

const Form = ({
  containerImageClass,
  containerPadding,
  submitText,
  displayLogo,
  headerText,
  children,
  ...rest
}) => {
  const classes = useStyles({ containerPadding });

  return (
    <Grid container item xs={12} justify='center'>
      {
        displayLogo && (<>
          <Grid container item sm={1} />

          <Hidden xsDown>
            <Grid container item sm={5} classes={{ container: containerImageClass }} justify="center" alignItems="center">
              <img src="/images/header-logo.png" alt='header-logo'/>
            </Grid>
          </Hidden>
        </>)
      }

      <Grid
        container
        item
        sm={displayLogo ? 5 : 12}
        xs={displayLogo ? 11 : 12}
        justify='center'
        classes={{ container: classes.formContainer }}
      >
        <Grid container item xs={12} justify='center' alignItems='center' direction='column'>
          <Grid item classes={{ root: classes.textRoot }}>
            <Typography variant='h3' color='textSecondary'>
              {headerText}
            </Typography>
          </Grid>

          <Formik {...rest}>
            {({ submitForm, isSubmitting, resetForm, isValid, errors }) => {
              // console.log(errors);

              return (
                <FormikForm>
                  <Grid container item xs={12} justify='center'>
                    {children}

                    <Grid
                      container
                      item
                      spacing={8}
                      justify='center'
                      classes={{ container: classes.buttonsContainer }}
                    >
                      <Grid item xs={12} md={6}>
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          fullWidth
                          disabled={!isValid || isSubmitting}
                          onClick={submitForm}
                          style={{backgroundColor:'#A2DEE4'}}
                          classes={{ root: classes.button }}
                        >
                          {submitText}
                        </Button>
                      </Grid>
                      
                      <Grid item xs={12} md={6}>
                        <Button
                          variant='contained'
                          color='secondary'
                          fullWidth
                          disabled={isSubmitting}
                          onClick={resetForm}
                          classes={{ root: classes.button }}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormikForm>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
      
      { displayLogo && <Grid container item sm={1} /> }
    </Grid>
  );
}

Form.propTypes = {
  containerImageClass: PropTypes.string,
  containerPadding: PropTypes.number,
  submitText: PropTypes.string,
  displayLogo: PropTypes.bool,
  headerText: PropTypes.string,
}

Form.defaultProps = {
  submitText: 'Submit',
  displayLogo: true,
}

export default memo(Form);
