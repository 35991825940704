import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CorrectIcon from '@material-ui/icons/CheckCircleOutline';
import WrongIcon from '@material-ui/icons/RemoveCircleOutline';
import TotalIcon from '@material-ui/icons/FilterNone';
import PercentageIcon from '@material-ui/icons/TrackChanges';

import { checkIfShowCoursesInExamMode } from 'reduxConfig/selectors';
import { renderComponentOrFunction } from 'utils';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 8*5,
  },
  icon: {
    width: '100%',
    maxWidth: '4vh',
    height: 'auto',
  },
  correctIcon: {
    color: green[500],
  },
  wrongIcon: {
    color: red[500],
  },
}));

const Row = ({
  Icon = CorrectIcon,
  classes,
  label,
  value,
}) => (
  <Grid container item>
    <Grid container item xs={6} justify='center'>
      <Icon classes={{ root: classes }} />
    </Grid>

    <Grid container item xs={6} justify='center' alignItems='center'>
      <Typography variant='h5' align='center'>
        <Box component='span' fontWeight='fontWeightBold'>{label}:&nbsp;</Box>
        <Box component='span'>{value}</Box>
      </Typography>
    </Grid>
  </Grid>
)

const DefaultFooter = memo(() => {
  const history = useHistory();
  const showCoursesInExamMode = useSelector(checkIfShowCoursesInExamMode);

  // This check is done because in the special case where you can
  // view "All Courses" while in exam mode (i.e. you're FLIGNO or ADMIN),
  // we want to make sure the user is sent to 'exam-list'.
  //
  // For all other cases, just redirect to 'courses',
  // and if user is not logged in the other redirects will take you home:
  const page = showCoursesInExamMode ? 'exam-list' : 'courses';

  return (
    <Grid container item justify='center' xs={4}>
      <Button
        variant='contained'
        color='primary'
        fullWidth
        size='large'
        onClick={() => history.push(`/${page}`)}
      >
        Go back
      </Button>
    </Grid>
  )
})

const ExamResults = ({
  questionsCount,
  correctAnswerCount,
  Footer,
}) => {
  const classes = useStyles();

  const wrongAnswerCount = questionsCount - correctAnswerCount;
  const percentageCorrect = ((correctAnswerCount / questionsCount) * 100).toFixed(2);

  return (
    <Grid
      container
      item
      justify='center'
      direction='column'
      alignItems='center'
      spacing={6}
      classes={{ root: classes.container }}
    >
      <Grid container item justify='center'>
        <Typography variant='h4' className="mb-2">Exam Results</Typography>
      </Grid>

      <Grid container item xs={6} justify='center' spacing={4} className="border-2 border-gray-500 rounded-md bg-gray-200 mb-8">
        <Row
          Icon={CorrectIcon}
          classes={`${classes.icon} ${classes.correctIcon}`}
          label='Correct'
          value={correctAnswerCount}
        />

        <Row
          Icon={WrongIcon}
          classes={`${classes.icon} ${classes.wrongIcon}`}
          label='Wrong'
          value={wrongAnswerCount}
        />

        <Row
          Icon={TotalIcon}
          classes={classes.icon}
          label='Total items'
          value={questionsCount}
        />

        <Row
          Icon={PercentageIcon}
          classes={classes.icon}
          label='Grade'
          value={`${percentageCorrect}%`}
        />
      </Grid>
      
      <Grid container item justify='center'>
        {renderComponentOrFunction(Footer)}
      </Grid>
    </Grid>
  )
}

ExamResults.propTypes = {
  questionsCount: PropTypes.number.isRequired,
  correctAnswerCount: PropTypes.number,
  Footer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
}

ExamResults.defaultProps = {
  questions: [],
  answers: {},
  correctAnswerCount: 0,
  handleChangeRadio: noop,
  firstQuestionNumber: 1,
  Footer: DefaultFooter,
}

export default memo(ExamResults);
