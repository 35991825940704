import React, {} from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Pagination from '@material-ui/lab/Pagination';

import Question from '../../common/Question'

const Questions = ({
  submitting,
  page,
  course,
  functionName,
  answers,
  questions,
  onPageChange,
  onChoice,
  onSubmit
}) => {
  const theme = useTheme()

  const handleChoice = (questionId, choiceId) => {

  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{paddingTop: 0}}>
        <Box style={{padding: theme.spacing(3), backgroundColor: theme.palette.primary.main, color: theme.palette.common.white}}>
          <Typography variant="h5">
            { functionName }
          </Typography>
          <Typography variant="h5">
            { course }
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box style={{padding: theme.spacing(3)}}>
          {
            questions.slice((page-1),page).map((question, index) => (
              <Question
                highlight={false}
                key={'question-'+index}
                text={question.text}
                choices={question.choices}
                onSelect={(e, choiceId) => onChoice(Number(question.id), Number(choiceId))}
                value={window._.find(answers, (o) => o.id == question.id)}
              />
            ))
          }
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box style={{padding: theme.spacing(3), display: 'flex', justifyContent: 'space-between'}}>
          <span>&nbsp;</span>
          <Pagination
            count={questions.length}
            color="primary"
            page={page}
            onChange={onPageChange}
          />
          {
            (() => {
              if(questions.length == page) {
                if(submitting) {
                  return (
                    <Button variant="contained" color="primary">Checking. . .</Button>
                  )
                }
                return (
                  <Button variant="contained" color="primary" onClick={() => onSubmit(answers)}>Submit</Button>
                )
              }
              return (
                <span>&nbsp;</span>
              )
            })()
          }
        </Box>
      </Grid>
    </Grid>
  )
}
Questions.propTypes = {
  submitting: PropTypes.bool,
  page: PropTypes.number.isRequired,
  course: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  functionName: PropTypes.string.isRequired,
  questions: PropTypes.array,
  onPageChange: PropTypes.func,
  onChoice: PropTypes.func,
  onSubmit: PropTypes.func
}
Questions.defaultProps = {
  submitting: false
}

export default Questions