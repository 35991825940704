import { createSelector } from 'reselect'
import moment from 'moment';
import memoize from 'memoize-one';

const _getMoment = x => {
  if (!x) return null;

  return moment(Number(x));
}

export const getCurrentUser = state => ({
  ...state.currentUser,
  rankId: state?.currentUser?.rank?.id,
  rankName: state?.currentUser.rank?.name,
  birthday: _getMoment(state?.currentUser?.birthday),
  graduatedOn: _getMoment(state?.currentUser?.graduatedOn),
})

const getAllSavedExamAnswers = state => state.savedExamAnswers;
const getMisc = state => state.misc;

export const getCurrentUsername = createSelector([getCurrentUser], user =>
  user.username || '?'
)

export const getAuthToken = createSelector([getCurrentUser], user =>
  user.token || null
)

export const checkIfAdmin = createSelector([getCurrentUser], user =>
  Boolean(user.isAdmin)
)

export const checkIfExamCheatsEnabled = createSelector([getCurrentUser], user =>
  Boolean(user.examCheatsEnabled)
)

export const checkIfCanTakeExams = createSelector([getCurrentUser], user =>
  Boolean(user.canTakeExams)
)

export const getCourseIdForExamUserIsTaking = createSelector([getCurrentUser], user =>
  user.takingExamForCourseId
)

export const checkIfUserCurrentlyTakingExam = createSelector([getCourseIdForExamUserIsTaking], x => !!x)

export const checkIfCanAlwaysAccessCourse = createSelector([getCurrentUser], user =>
  Boolean(user.canAlwaysAccessCourse)
)
export const checkIfShowCoursesInExamMode = createSelector([getCurrentUser], user =>
  Boolean(user.showCoursesInExamMode)
)

export const getSavedExamAnswers = createSelector([getAllSavedExamAnswers], allSavedExamAnswers =>
  memoize(examKey => examKey ? allSavedExamAnswers[examKey] : {})
)

export const getSiteVisitors = createSelector([getMisc], misc => misc.siteVisitors || 0)
