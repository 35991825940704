import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { get, flatten } from 'lodash';
import { useSnackbar } from 'notistack';

import { PASSING_PERCENT } from '@nereus/shared/constants';
import { EXAM_LIST_PAGE as QUERY } from 'constants/queries';
import B from 'components/common/B';
import Loading from 'components/common/Loading';
import Exam from './Exam';
import ExitExamModeButton from './ExitExamModeButton';
import PercentDisplay from './PercentDisplay';
import {
  NAME_XS,
  EXAM_XS,
  SCORE_XS,
  PERCENT_XS,
  ATTEMPTS_XS,
  BUTTON_XS,
} from './constants';
import { useQuery } from 'utils';

const ExamList = () => {
  const { courseId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { data, LoadingProps } = useQuery(QUERY, {
    enqueueSnackbar,
    errorMessage: 'Error fetching your exams',
    variables: { courseId },
  });
  
  const categories = get(data, 'courseCategoriesWithExamFlag.courseCategories', []);
  const maxExamAttempts = get(data, 'courseCategoriesWithExamFlag.maxExamAttempts', null);

  const flattenedExams = flatten(categories.map(category => category.exams));
  const allExamsAttempted = flattenedExams.every(x => x.attempts > 0);
  const mostAttemptedExamCount = flattenedExams.reduce((a, { attempts:b }) => Math.max(a, b), 0);
  const allExamsHaveSameAttempts = flattenedExams.every(exam => exam.attempts === mostAttemptedExamCount);
  const totalScore = flattenedExams.reduce((sum, x) => sum + x.score, 0);
  const totalItems = flattenedExams.reduce((sum, x) => sum + x.numberOfQuestions, 0);
  const percent = (totalScore / totalItems) * 100;
  const canExitExam = allExamsAttempted && allExamsHaveSameAttempts;

  return (
    <Loading {...LoadingProps}>
      <Grid container direction='column' spacing={10} style={{margin: 0, width: ''}}>
        <Grid container item xs={12} justify='space-between' spacing={10}>
          <Grid container item xs={12} spacing={4}>
            <Grid className="py-3 px-4" alignItems='center' container>
              <Grid container item xs={NAME_XS} className='pr-2'><B>Category</B></Grid>
              <Grid container item xs={EXAM_XS}>
                <Grid item xs={SCORE_XS}><B>Score</B></Grid>
                <Grid item xs={PERCENT_XS}><B>Rating</B></Grid>
                <Grid item xs={ATTEMPTS_XS}><B>Attempts</B></Grid>
                <Grid item xs={BUTTON_XS} className='px-4'><B>Exams</B></Grid>
              </Grid>
            </Grid>
          </Grid>

          {categories.map(category => {
            return (
              <Grid
                key={category.id}
                container
                item
                spacing={4}
                justify='space-between'
                alignItems='center'
                className='py-3 mb-2'
              >
                <Grid className="bg-white py-3 px-4 rounded" alignItems='center' container>
                  <Grid container item xs={NAME_XS}>
                    {category.name}
                  </Grid>

                  <Grid container item xs={EXAM_XS}>
                    <Exam
                      exams={category.exams}
                      maxExamAttempts={maxExamAttempts}
                      mostAttemptedExamCount={mostAttemptedExamCount}
                      allExamsHaveSameAttempts={allExamsHaveSameAttempts}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}

          {
            allExamsAttempted && (<>
              <Grid
                container
                item
                spacing={4}
                justify='space-between'
                alignItems='center'
                className='py-3 mb-2 pt-8'
              >
                <Grid className="bg-white rounded py-3 px-4" alignItems='center' container>
                  <Grid container item xs={NAME_XS}><B>AVERAGE RATING</B></Grid>
                  <Grid container item xs={EXAM_XS}>
                    <Grid item xs={SCORE_XS} />
                    <Grid item xs={PERCENT_XS}>
                      <PercentDisplay percent={percent} passingPercent={PASSING_PERCENT.course} />
                    </Grid>
                    <Grid item xs={ATTEMPTS_XS + BUTTON_XS} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={10} xs={12}>
                <Grid container item xs={12} spacing={4}>
                  <ExitExamModeButton disabled={!canExitExam} />
                </Grid>
              </Grid>
            </>)
          }
        </Grid>
      </Grid>
    </Loading>
  )
}

export default memo(ExamList);
