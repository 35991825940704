import { gql } from '@apollo/client';

const _USER_FRAGMENT = gql`
  fragment UserFields on User {
    id
    username
    email
    isAdmin
    canTakeExams
    canAlwaysAccessCourse
    showCoursesInExamMode
    takingExamForCourseId
    examCheatsEnabled
    firstName
    middleName
    lastName
    graduatedFrom
    graduatedOn
    birthday
    address
    phoneNumber
    company
    referredBy
    rank {
      id
      name
    }
  }
`

const LOGIN = gql`
  ${_USER_FRAGMENT}

  mutation LoginMutation(
    $username: String!
    $password: String!
  ) {
    login(
      username: $username,
      password: $password
    ) {
      token
      user {
        ...UserFields
      }
    }
  }
`

const REGISTER = gql`
  ${_USER_FRAGMENT}

  mutation RegisterMutation(
    $username: String!
    $email: String!
    $password: String!
    $password2: String!
    $firstName: String!
    $middleName: String!
    $lastName: String!
    $rank: Int!
    $graduatedFrom: String!
    $graduatedOn: String!
    $birthday: String!
    $address: String!
    $phoneNumber: String!
    $company: String
    $referredBy: String
  ) {
    signup(
      username: $username,
      email: $email,
      password: $password,
      password2: $password2,
      firstName: $firstName,
      middleName: $middleName,
      lastName: $lastName,
      rank: $rank
      graduatedFrom: $graduatedFrom,
      graduatedOn: $graduatedOn,
      birthday: $birthday,
      address: $address,
      phoneNumber: $phoneNumber,
      company: $company,
      referredBy: $referredBy,
    ) {
      token
      user {
        ...UserFields
      }
    }
  }
`

const UPDATE_PROFILE = gql`
  ${_USER_FRAGMENT}

  mutation updateProfile(
    $currentPassword: String!
    $email: String
    $newPassword: String
    $firstName: String
    $middleName: String
    $lastName: String
    $rank: Int
    $graduatedFrom: String
    $graduatedOn: String
    $birthday: String
    $address: String
    $phoneNumber: String
    $company: String
    $referredBy: String
  ) {
    updateProfile(
      currentPassword: $currentPassword,
      email: $email,
      newPassword: $newPassword,
      firstName: $firstName,
      middleName: $middleName,
      lastName: $lastName,
      rank: $rank
      graduatedFrom: $graduatedFrom,
      graduatedOn: $graduatedOn,
      birthday: $birthday,
      address: $address,
      phoneNumber: $phoneNumber,
      company: $company,
      referredBy: $referredBy,
    ) {
      token
      user {
        ...UserFields
      }
    }
  }
`

const APPLY_COURSE = gql`
  mutation applyCourse($courseId: Int!) {
    applyCourse(courseId: $courseId) {
      id
      code
      name
      userCourse {
        status
        approverDenierMessage
      }
    }
  }
`

const PAY_COURSE = gql`
  mutation payCourse(
    $courseId: Int!,
    $receiptImage: Upload!,
    $receiptComment: String,
    $receiptAmountpaid: String
  ) {
    payCourse(
      courseId: $courseId,
      receiptImage: $receiptImage,
      receiptComment: $receiptComment,
      receiptAmountpaid: $receiptAmountpaid
    ) {
      id
      status
      course {
        id
      }
      receiptPath
    }
  }
`

const APPROVE_DENY_COURSE_APPLICATION = gql`
  mutation approveDenyCourseApplication(
    $id: Int!,
    $approve: Boolean!,
    $approverDenierMessage: String
  ) {
    approveDenyCourseApplication(
      id: $id,
      approve: $approve,
      approverDenierMessage: $approverDenierMessage
    ) {
      id
      approverDenierMessage
    }
  }
`

const TOGGLE_EXAM_MODE = gql`
  mutation toggleExamMode($courseId: Int) {
    toggleExamMode (courseId: $courseId)
  }
`

const BEGIN_EXAM = gql`
  mutation beginExam($examId: Int!) {
    beginExam (examId: $examId) {
      id
      timeSpent
      exam {
        name
        courseCategory {
          course {
            name
          }
        }
      }
      attemptNumber
      qas {
        id
        choice {
          id
        }
        question {
          id
          text
          points
          choices {
            id
            text
            isCorrect # null if you are not an admin
          }
          courseItem {
            id
            name
          }
        }
      }
    }
  }
`

const BEGIN_EXAMV2 = (isCheatsheet) => gql`
  mutation beginExam($examId: Int!) {
    beginExam (examId: $examId) {
      id
      timeSpent
      exam {
        name
        courseCategory {
          course {
            name
          }
        }
      }
      attemptNumber
      qas {
        id
        choice {
          id
        }
        question {
          id
          text
          points
          choices {
            id
            text
            ${isCheatsheet ?'isCorrect' :''}
          }
          courseItem {
            id
            name
          }
        }
      }
    }
  }
`

const ANSWER_EXAM = gql`
  mutation answerExam($examId: Int!, $answers: [UserAnswerInput!]) {
    answerExam(examId: $examId, answers: $answers) {
      id
      attemptNumber
      score
    }
  }
`

const LOG_SITE_VISIT = gql`
  ${_USER_FRAGMENT}

  mutation logSiteVisit {
    logSiteVisit {
      count
      ip
      serverVersion
      serverEnv
      user {
        ...UserFields
      }
    }
  }
`


const CLEAR_BROWSER_RECORD = gql`
  mutation clearBrowserRecord($userId: Int!) {
    clearBrowserRecord(userId: $userId) {
      message
    }
  }
`

const UPDATE_QUESTION = gql`
  mutation UpdateQuestion ($question: UpdateQuestion!) {
    updateQuestion(question: $question) {
      message
      question {
        id
        text
        isRetired
        courseItem {
          id
          name
        }
        choices {
          id
          isCorrect
          text
        }
      }
    } 
  }
`
const RETIRE_QUESTION = gql`
  mutation RetireQuestion ($questionId: Int!) {
    retireQuestion(questionId: $questionId) {
      message
      question {
        id
        text
        isRetired
        courseItem {
          id
          name
        }
        choices {
          id
          isCorrect
          text
        }
      }
    } 
  }
`

const IMPORT_COURSE = gql`
  mutation importCourse(
    $courseId: Int!,
    $file: Upload!
  ) {
    importCourse(
      courseId: $courseId,
      file: $file
    ) {
      message
    }
  }
`

const EXAM_TIME_SPENT = gql`
  mutation examTimeSpent(
    $userExamId: Int!,
    $timeSpent: String!
  ) {
    examTimeSpent(
      userExamId: $userExamId,
      timeSpent: $timeSpent
    ) {
      message
    }
  }
`

const BEGIN_EXAM_REVIEW_CHECK = gql`
  mutation beginExamReviewCheck(
    $courseItemId: Int!,
    $questions: [ReviewCheckInput!]
  ) {
    beginExamReviewCheck(
      courseItemId: $courseItemId
      questions: $questions
    ) {
      totalItems,
      correct,
      wrong,
      grade
    }
  }
`

const BEGIN_EXAM_TRIAL_CHECK = gql`
  mutation beginExamTrialCheck(
    $examId: Int!,
    $answers: [TrialCheckInput!]
  ) {
    beginExamTrialCheck(
      examId: $examId
      answers: $answers
    ) {
      correct
    }
  }
`

export {
  LOGIN,
  REGISTER,
  UPDATE_PROFILE,
  APPLY_COURSE,
  PAY_COURSE,
  APPROVE_DENY_COURSE_APPLICATION,
  TOGGLE_EXAM_MODE,
  BEGIN_EXAM,
  BEGIN_EXAMV2,
  ANSWER_EXAM,
  LOG_SITE_VISIT,
  CLEAR_BROWSER_RECORD,
  UPDATE_QUESTION,
  RETIRE_QUESTION,
  IMPORT_COURSE,
  EXAM_TIME_SPENT,
  BEGIN_EXAM_REVIEW_CHECK,
  BEGIN_EXAM_TRIAL_CHECK
};
