import { gql } from '@apollo/client';

const HELLO = gql`{ hello }`;

const RANKS = gql`
  query {
    ranks {
      id
      name
    }
  }
`

const GET_USER_TAKING_EXAM_FOR_COURSE_ID = gql`
  query user {
    currentUser {
      takingExamForCourseId
    }
  }
`

const _EXAM_FRAGMENT = gql`
  fragment NonCalculatedExamFields on Exam {
    id
    name
  }
`

const _COURSE_CATEGORY_FRAGMENT = gql`
  fragment NonCalculatedCourseCategoryFields on CourseCategory {
    id
    name
    items {
      id
      name
    }
  }
`

const _COURSES_FRAGMENT = gql`
  ${_COURSE_CATEGORY_FRAGMENT}
  ${_EXAM_FRAGMENT}

  fragment CoursesFields on Course {
    id
    code
    name
    servicesOfferedLabel
    description
    price
    maxExamAttempts
    categories {
      ...NonCalculatedCourseCategoryFields
      hasExams
      exams {
        ...NonCalculatedExamFields
      }
    }
  }
`

const COURSES = gql`
  ${_COURSES_FRAGMENT}

  query {
    courses {
      ...CoursesFields
    }
  }
`

const COURSES_WITH_USER_COURSE = gql`
  ${_COURSES_FRAGMENT}

  query {
    courses {
      ...CoursesFields
      userCourse {
        status
        approverDenierMessage
      }
    }
  }
`

const COURSES_TRIAL_EXAM_BUTTON = gql`
  ${_EXAM_FRAGMENT}

  query {
    courses {
      name
      categories {
        name
        exams {
          ...NonCalculatedExamFields
        }
      }
      maxExamAttempts
    }
  }
`

// TODO "canOnlyTakeTrialExam" flag no longer needed here
const EXAM_LIST_PAGE = gql`
  ${_COURSE_CATEGORY_FRAGMENT}
  ${_EXAM_FRAGMENT}

  query courseCategoriesWithExamFlag($courseId: Int) {
    courseCategoriesWithExamFlag(courseId: $courseId) {
      courseCategories {
        id
        ...NonCalculatedCourseCategoryFields
        exams {
          ...NonCalculatedExamFields
          attempts
          score
          numberOfQuestions:maxQuestionsToUse
        }
      }
      maxExamAttempts
      canOnlyTakeTrialExam
    }
  }
`

const COURSE_APPROVALS = gql`
  query courseApprovals($statuses: [String]) {
    courseApprovals(statuses: $statuses) {
      id
      receiptPath
      receiptComment
      receiptAmountpaid
      status
      course {
        code
        name
      }
      user {
        id
        username
      }
      appliedOn
      paymentPostedOn
      approvedDeniedOn
      approverDenierMessage
    }
  }
`

// TODO Probably can use the user fragment from mutations here
const USERS = gql`
  query users($search: String) {
    users(search: $search) {
      id
      username
      email
      firstName
      middleName
      lastName
      graduatedFrom
      graduatedOn
      birthday
      address
      phoneNumber
      company
      referredBy
      rank {
        name
      }
      isAdmin
      isDev
      canTakeExams
      canAlwaysAccessCourse
      showCoursesInExamMode
      examCheatsEnabled
      createdOn
      courses {
        status
        appliedOn
        course {
          name
          code
        }
        user {
          username
        }
      }
    }
  }
`

const BEGIN_EXAM_TRIAL = gql`
  query beginExamTrial($examId: Int!) {
    beginExamTrial(examId: $examId) {
      course
      function
      questions {
        id
        text
        choices {
          id
          text
          isCorrect
        }
      }
    }
  }
`

const BEGIN_EXAM_REVIEW = gql`
  query beginExamReview($courseItemId: Int!) {
    beginExamReview(courseItemId: $courseItemId) {
      name
      competence {
        name
        category {
          name
          course {
            name
          }
        }
      }
      questions {
        id
        text
        choices {
          id
          text
          isCorrect
        }
      }
    }
  }
`

const ADMIN_DASHBOARD = gql`
  query adminDashboard($startDate: String, $endDate: String) {
    adminDashboard(startDate: $startDate, endDate: $endDate) {
      registeredUsers,
      registeredUsersPaid,
      registeredUsersToday,
      examineesPassed,
      examineesFailed,
      examineesNoResponse,
      earningsMonth,
      earningsYear,
      earningsPeriod,
      modulesCompleted,
      pendingRequests,
      siteVisitors,
    }
  }
`

const LOGOUT_USER = gql`
  query logoutUser {
    logoutUser {
      message
    }
  }
`

const FUNCTION_INFO_AND_COMPETENCES = gql`
  query functionInfoAndCompetences ($functionId: Int, $search: String, $compentence: String, $filter: String, $rowsPerPage: Int, $page: Int) {
    functionInfoAndCompetences(functionId: $functionId, search: $search, compentence: $compentence, filter: $filter, rowsPerPage: $rowsPerPage, page: $page) {
      detail
      competences {
        id
        name
      }
      questions {
        id
        text
        isRetired
        courseItem {
          id
          name
        }
        choices {
          id
          text
          isCorrect
        }
      }
    }
  }
`

export {
  HELLO,
  RANKS,
  GET_USER_TAKING_EXAM_FOR_COURSE_ID,
  COURSES,
  COURSES_WITH_USER_COURSE,
  COURSES_TRIAL_EXAM_BUTTON,
  EXAM_LIST_PAGE,
  COURSE_APPROVALS,
  USERS,
  BEGIN_EXAM_TRIAL,
  BEGIN_EXAM_REVIEW,
  ADMIN_DASHBOARD,
  LOGOUT_USER,
  FUNCTION_INFO_AND_COMPETENCES
};
