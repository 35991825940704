import React, { useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { BEGIN_EXAM_TRIAL } from 'constants/queries';
import { BEGIN_EXAM_TRIAL_CHECK } from 'constants/mutations';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { GlobalStateContext } from '../../global.state'
import { useActor } from '@xstate/react'

import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

import Question from '../../common/Question'
import Result from '../../common/Result'
import Failed from './Failed'
import Questions from './Questions'


function TrialPage()
{
  const history = useHistory()
  const competence = {}
  const theme = useTheme()
  const Services = useContext(GlobalStateContext)
  const TrialMachine = useActor(Services.TrialExamService)
  const page = TrialMachine[0].context.page
  const questions = TrialMachine[0].context.questions
  const answered = TrialMachine[0].context.answered
  const examResult = TrialMachine[0].context.result
  const { examId } = useParams()
  const [getExam] = useLazyQuery(BEGIN_EXAM_TRIAL, {
    variables: {
      examId: Number(examId)
    },
    onCompleted(data, clientOptions) {
      TrialMachine[1]({type: 'SUCCESS', data: data.beginExamTrial})
    },
    onError(error, clientOptions) {
      TrialMachine[1]({type: 'FAILURE'})
    }
  })
  const [checkAnswer] = useMutation(BEGIN_EXAM_TRIAL_CHECK, {
    onCompleted(data, clientOptions) {
      TrialMachine[1]({type: 'SUCCESS', data: data.beginExamTrialCheck})
    },
    onError(data, clientOptions) {
      TrialMachine[1]({type: 'FAILURE'})
    }
  })
  const handleRetry = () => {
    getExam()
    TrialMachine[1]('RETRY')
  }
  const handleQuestionChange = (e, newPage) => {
    TrialMachine[1]({type: 'PAGE', data: newPage})
  }
  const handleChoice = (questionId, choiceId) => {
    TrialMachine[1]({type: 'ANSWERED_QUESTION', data: {id: questionId, choiceId: choiceId}})
  }
  const handleSubmit = (answers) => {
    // console.log(answers)
    checkAnswer({
      variables: {
        examId: Number(examId),
        answers: answers
      }
    })
    TrialMachine[1]('SUBMIT')
    // setTimeout(() => {
    //   TrialMachine[1]('SUCCESS')
    //   // TrialMachine[1]('FAILURE')
    // }, 3000)
  }
  const handleRedirect = () => {
    history.replace('/')
  }

  useEffect(() => {
    getExam()
    TrialMachine[1]('FETCH')
  }, [])
  return (
    <Box style={{padding: theme.spacing(3), width: '100%'}}>
      <Paper elevation={5}>
        {
          (() => {
            if(TrialMachine[0].matches('fetch.process')) {
              return ''
            }
            if(TrialMachine[0].matches('fetch.failure')) {
              return (
                <Failed
                  onRetry={handleRetry}
                />
              )
            }
            if(TrialMachine[0].matches('submit.success')) {
              return (
                <Result
                  name={window._.get(TrialMachine[0].context, 'function', 'Course')}
                  correct={examResult.correct}
                  wrong={examResult.wrong}
                  items={examResult.items}
                  grade={examResult.grade}
                  onRedirect={handleRedirect}
                />
              )
            }

            return (
              <Questions
                submitting={TrialMachine[0].matches('submit.process')}
                page={page}
                answers={answered}
                course={window._.get(TrialMachine[0].context, 'course', '')}
                functionName={window._.get(TrialMachine[0].context, 'function', 'Course')}
                questions={questions}
                onPageChange={handleQuestionChange}
                onChoice={handleChoice}
                onSubmit={handleSubmit}
              />
            )
          })()
        }
      </Paper>
    </Box>
  )
}


export default TrialPage