import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
} from '@material-ui/core';
import { get, isArray } from 'lodash';
import memoizeOne from 'memoize-one';
import { Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import Loading from 'components/common/Loading';
import { COURSES_TRIAL_EXAM_BUTTON as QUERY } from 'constants/queries';
import { useQuery } from 'utils';

const getCoursesWithExams = memoizeOne(data => {
  const courses = get(data, 'courses', []);
  let coursesWithExams = [];
  
  // TODO Maybe a better way
  if(courses.length > 0) {
    for (let course of courses) {
      let categoriesWithExams = [];

      for (let category of course.categories) {
        if (category.exams.length > 0) {
          categoriesWithExams.push(category.name);
        }
      }

      if (categoriesWithExams.length > 0) {
        // The 'text', 'items' thing is done deliberately to look like recursion
        // I just have a feeling this might be useful somewhere else. One day. Perhaps.
        coursesWithExams.push({
          text: course.name,
          items: course.categories
            .filter(({name}) => categoriesWithExams.includes(name))
            .map(x => ({
              text: x.name,
              items: x.exams.map(x => ({
                text: x.name,
                value: Number(x.id),
              })),
            })),
        })
      }
    }
  }

  return coursesWithExams;
})

const getCurrentChoices = memoizeOne((courses, selections = []) => {
  if (selections.length <= 0) return courses;

  let x = courses;

  for (let s of selections) {
    x = x.find(({ text }) => text === s);
    x = x.items || x.value;
  }

  return x;
})

const Step = memo(({ nextStep, choices = [] }) => {
  return (
    <List>
      {choices.map(({ text }) => {
        const handleClick = () => nextStep(text);

        return (
          <ListItem key={text} button onClick={handleClick}>
            {text}
          </ListItem>
        )
      })}
    </List>
  )
})

const HeaderTrialExamDialog = ({
  isOpen,
  closeModal,
  autoPickOnlyItem,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [path, setPath] = useState([]);

  const { data, LoadingProps } = useQuery(QUERY, {
    enqueueSnackbar,
    errorMessage: 'Error fetching list of exams',
  });

  // TODO Honestly should be doing this backend, but wanting to keep it simple
  // Also feeling a bit lazy
  const courses = getCoursesWithExams(data);
  const currentChoices = getCurrentChoices(courses, path);

  const nextStep = text => setPath([...path, text]);
  // const prevStep = () => setPath(path.slice(0, -1));

  // If this is longer an array, then currentChoices is now an int (the id of the exam)
  // and we've arrived at a value we can now redirect to
  if (!isArray(currentChoices)) {
    closeModal();
    return <Redirect push to={`/exam-trials/${currentChoices}`} />
  }

  // Putting this logic here feels dangerous. TODO Check if fine
  if (autoPickOnlyItem && currentChoices.length === 1) {
    nextStep(currentChoices[0].text);
  }

  let title;
  switch (path.length) {
    case 0: title = 'a course'; break;
    case 1: title = 'a function'; break;
    case 2: title = 'an exam'; break;
    default: title = '?';
  }

  return (
    <Dialog
      open={isOpen}
      {...rest}
      onClose={closeModal}
      onExited={() => setPath([])}
    >
      <Loading {...LoadingProps}>
        <DialogTitle>Select {title}</DialogTitle>

        <DialogContent>
          <Step nextStep={nextStep} choices={currentChoices} />
        </DialogContent>
      </Loading>
    </Dialog>
  )
}

HeaderTrialExamDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  autoPickOnlyItem: PropTypes.bool,
}

HeaderTrialExamDialog.defaultProps = {
  autoPickOnlyItem: true,
}

export default memo(HeaderTrialExamDialog)
