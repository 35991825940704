import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
// Material
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// Icons
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityIcon from '@material-ui/icons/Visibility';
// Components
import Alerts from '../../../common/Alerts'
import InfoDialog from './Components/InfoDialog'
// States
import { GlobalStateContext } from '../../../global.state'
import { useActor } from '@xstate/react'
// GraphQL
import { gql, useQuery } from '@apollo/client';
import { COURSES } from '../../../../constants/queries'

const rowsPerPage = 10
// const rows = []

function CourseImportPage({

}) {
  const history = useHistory()
  const theme = useTheme()
  const Services = useContext(GlobalStateContext)
  const ManageCourseState = useActor(Services.ManageCourseService)
  const rows = ManageCourseState[0].context.list
  const page = ManageCourseState[0].context.page;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  // ManageCourseState[0]
  useQuery(COURSES, {
    onCompleted(data) {
      // console.log(data)
      ManageCourseState[1]({type: 'SUCCESS', data: data.courses})
    },
    onError(error) {
      // console.error(error)
      ManageCourseState[1]('FAILURE')
    }
  });
  // const [page, setPage] = useState(0)

  const handleChangePage = (event, newPage) => {
    // setPage(newPage)
    ManageCourseState[1]({type: 'SET_PAGE', data: newPage})
  }
  useEffect(() => {
    ManageCourseState[1]('FETCH')
  }, [])

  return (
    <Paper elevation={3} style={{width: '100%', padding: theme.spacing(1), margin: theme.spacing(2)}}>
      <InfoDialog
        data={ManageCourseState[0].context.selected || {}}
        open={ManageCourseState[0].matches('info.show')}
        onClose={() => ManageCourseState[1]('CLOSE')}
      />

      <Alerts.Loading
        open={ManageCourseState[0].matches('fetch.process')}
        message="Fetching courses"
      />
      <Alerts.Success
        open={ManageCourseState[0].matches('fetch.success')}
        message="Courses list updated!"
        onClose={() => ManageCourseState[1]('CLOSE')}
      />
      <Alerts.Failure
        open={ManageCourseState[0].matches('fetch.failure')}
        message="Failed to fetch courses!"
        onClose={() => ManageCourseState[1]('CLOSE')}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right">Code</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Service Label</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow key={'course-row-'+index}>
                      <TableCell component="th" id={labelId} align="right">
                        {row.code}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.servicesOfferedLabel || '(not defined)'}</TableCell>
                      <TableCell align="right">{Number(row.price).toLocaleString()}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Detail">
                          <IconButton onClick={() => ManageCourseState[1]({type: 'INFO', data:row})}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Manage">
                          <IconButton onClick={() => history.push('/dev/course/'+row.id)}>
                            <SettingsIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

CourseImportPage.propTypes = {

}

export default CourseImportPage