import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
// import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { PASSING_PERCENT } from '@nereus/shared/constants';
// Icons
import CheckIcon from '@material-ui/icons/Check';
// Components
import Button from '../../../Button'

import { useLazyQuery, gql } from '@apollo/client'
import {
  GET_USER_TAKING_EXAM_FOR_COURSE_ID as QUERY,
  // EXAM_LIST_PAGE
} from 'constants/queries';
import { TOGGLE_EXAM_MODE as MUTATION } from 'constants/mutations';
import { useGlobalDialog } from 'globalDialog';
import {
  Actions as ConfirmTakeExamActions,
  Title as ConfirmTakeExamTitle,
} from 'components/common/Courses/Course/TakeExamsButton';
import Paragraphs from 'components/common/Paragraphs';
import { useMutation } from 'utils';

const CONFIRM_TAKE_EXAM_MESSAGE = [
  'Please proceed with caution. Once you choose to take the exam,  you will not be able to exit the exam page unless you finish answering all the exam parts for the different functions of the course.',
  'For example, if the exam has four (4) parts, you need to finish all four (4) before you are allowed to exit the exam page and continue your review. Overall, you will have a maximum of eight (8) attempts to take the exam and pass it.',
  'By clicking to proceed, you agree that you have read the information above and you are aware of the possible outcomes for continuing.',
];

const TakeExamsButton = ({ courseId, disabled }) => {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar();
  const {
    open: openDialog,
    close: closeDialog,
  } = useGlobalDialog();
  // const [average, setAverage] = useState(0)
  const [mutate] = useMutation(MUTATION, {
    enqueueSnackbar,
    successMessage: null,
    errorMessage: 'Error sending request',
    variables: { courseId },
    update: (store, { data: { toggleExamMode: takingExamForCourseId }}) => {
      store.writeQuery({ query: QUERY, data: { currentUser: { takingExamForCourseId }}});
    },
  });

  // const [getCourseUserDetail, {}] = useLazyQuery(EXAM_LIST_PAGE, {
  //   variables: {
  //     courseId
  //   },
  //   onCompleted(data) {
  //     const categories = data.courseCategoriesWithExamFlag.courseCategories
  //       .map(category => {
  //         const exam = category.exams[0]
  //         const average = ((exam.score || 0) / exam.numberOfQuestions)*100

  //         return {
  //           ...category,
  //           items: exam.numberOfQuestions,
  //           score: exam.score || 0,
  //           average
  //         }
  //       })
  //     const total = categories.reduce((acc, curr) => {
  //       acc.items += curr.items
  //       acc.scores += curr.score
  //       return acc
  //     }, {
  //       items: 0,
  //       scores: 0
  //     })
  //     setAverage((total.scores/total.items)*100)
  //   },
  //   onError(error) {
  //     // console.log(error)
  //   }
  // })

  const handleClick = event => {
    event.stopPropagation();

    openDialog({
      message: <Paragraphs data={CONFIRM_TAKE_EXAM_MESSAGE} />,
      title: <ConfirmTakeExamTitle />,
      actions: <ConfirmTakeExamActions takeExam={mutate} closeDialog={closeDialog} />,
    });
  }
  // useEffect(() => {
  //   getCourseUserDetail()
  // }, [])
  
  return (() => {
    // if(average > 0 && average >= PASSING_PERCENT.course) {

    //   return (
    //     <Button
    //       color="success"
    //       variant='contained'
    //     >
    //       Passed&nbsp;<CheckIcon/>
    //     </Button>
    //   )
    // }

    return (
      <Button
        color="success"
        variant='contained'
        onClick={handleClick}
        disabled={disabled}
      >
        Take Exam(s)
      </Button>
    )
  })()
}

TakeExamsButton.propTypes = {
  courseId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
}

TakeExamsButton.defaultProps = {
  disabled: false,
}

export default memo(TakeExamsButton);
