import React, { memo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import B from 'components/common/B';
import { displayDate } from 'utils';

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: '50vw',
    maxHeight: 8*40,
    height: 'auto',
  },
  typography: {
    paddingTop: 32,
    paddingBottom: 32,
  },
}));

const CUSTOM_HEADER_TEXT = 'WEBSITE DATA REPORT';

const ReportPageHeader = ({ startDate:s, endDate:e }) => {
  const classes = useStyles();

  const x = (!s && !e)
    ? CUSTOM_HEADER_TEXT
    : `${CUSTOM_HEADER_TEXT} (${displayDate(s)} - ${displayDate(e)})`;

  return (<>
    <Grid container justify='center' align='center'>
      <Grid item xs={12}>
        <img
          className={classes.image}
          src='/images/header-logo.png'
          alt='header-logo'
        />
      </Grid>

      <Grid item xs={12}>
        <B variant='h5' align='center' className={classes.typography}>
          {x}
        </B>
      </Grid>
    </Grid>
  </>)
}

export default memo(ReportPageHeader);
