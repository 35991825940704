import React, { memo, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isObject, get, noop } from 'lodash';

import { COURSES as QUERY } from 'constants/queries';
import Loading from 'components/common/Loading';
import { useQuery } from 'utils';
import ServicesOfferedDialog from './Dialog';

const MOCK_EXAMS_TEXT = 'MOCK EXAMS FOR ALL OF THE COMPETENCIES';
const OTHER_TEXT = 'OTHERS, AS MAY DEEMED APPROPRIATE';

const useStyles = makeStyles((theme) => {
  // console.log(theme.palette);

  return ({
    buttonRoot: {
      backgroundColor: 'white',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      paddingRight: '2rem',
      paddingLeft: '2rem',
      marginBottom: '1rem',
      marginLeft: '1rem',
    },
    buttonText: {
      color: `${theme.palette.text.primary} !important`,
    },
  });
});

// Keeping it here for now, trying to reduce how much tailwind we have.
// It does look a bit different from the material-ui we're using tho.
// const ItemOld = ({ children }) => (
//   <Grid item xs={6} className="pr-4">
//     <p class="flex-grow bg-white py-4 px-8 mb-4 ml-4">
//       {children}
//     </p>
//   </Grid>
// )

const Item = ({
  children,
  row,
  openModal = noop,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={6}>
      <Button
        fullWidth={true}
        onClick={() => openModal(row)}
        disabled={!row}
        classes={{
          root: classes.buttonRoot,
          label: classes.buttonText,
          text: classes.buttonText,
        }}
      >
        {children || get(row, 'servicesOfferedLabel') || get(row, 'name')}
      </Button>
    </Grid>
  )
}

const ServicesOffered = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const { data, LoadingProps } = useQuery(QUERY);

  const courseRows = get(data, 'courses', []);
  // TODO Probably a better way, but inserts some buttons
  // that don't do anything, in positions as seen in the design:
  const rows = courseRows.length > 1
    ? [
      ...courseRows.slice(0, courseRows.length - 1),
      MOCK_EXAMS_TEXT,
      ...courseRows.slice(courseRows.length - 1),
      OTHER_TEXT,
    ] : [
      ...courseRows,
      MOCK_EXAMS_TEXT,
      OTHER_TEXT,
    ]

  const closeModal = () => setModalOpen(false);
  const openModal = row => {
    setCurrentRow(row);
    setModalOpen(true);
  }
  
  return (<>
    <Grid container item xs={12}>
      <h2 className="text-xl font-medium mb-4 ml-4">
        Services Offered
      </h2>
    </Grid>

    <Grid container item xs={12} spacing={3}>
      <Loading {...LoadingProps}>
        {/* {rows.map(row => (<ItemOld key={row.id}>{row.text}</ItemOld>))} */}
        {rows.map(row => {
          if (isObject(row)) {
            return <Item key={row.id} row={row} openModal={openModal} />
          }
          
          return <Item key={row}>{String(row)}</Item>
        })}
      </Loading>
    </Grid>

    <ServicesOfferedDialog
      isOpen={modalOpen}
      closeModal={closeModal}
      courses={currentRow ? [currentRow] : null}
    />
  </>)
}

export default memo(ServicesOffered);
