import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { TextField } from 'formik-material-ui';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';

import { COURSE_STATUS as STATUS } from '@nereus/shared/constants';
import { payCourse as VALIDATION } from '@nereus/shared/validation';
import { COURSES_WITH_USER_COURSE as QUERY } from 'constants/queries';
import { PAY_COURSE as MUTATION } from 'constants/mutations';
import B from 'components/common/B';
import { useMutation/*, displayMoney*/ } from 'utils';
import PaymentOption from './PaymentOption';
import Dropzone from './Dropzone';

const useStyles = makeStyles((theme) => ({
  paddingBottom: {
    paddingBottom: 6*6,
  },
}));

const FileUploaderDialog = ({
  course,
  setCourseUploadingFor,
  ...rest
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const closeFileUploader = () => setCourseUploadingFor(null);

  const [mutate] = useMutation(MUTATION, {
    enqueueSnackbar,
    successMessage: 'File uploaded successfully',
    errorMessage: 'Error uploading file',
    onCompleted: (data, g) => {
      g(data);
      closeFileUploader();
    },
    update(store, { data: { payCourse: { course, ...userCourse }}}) {
      const { courses } = store.readQuery({ query: QUERY });

      store.writeQuery({ query: QUERY, data: { courses: courses.map(x => ({
        ...x,
        ...(x.id === course.id ? {
          ...course,
          userCourse: { ...x.userCourse, ...userCourse },
        } : {}),
      }))}});
    },
  });

  // const price = displayMoney(get(course, 'price', 0));
  const wasRejected = get(course, 'userCourse.status') === STATUS.rejected;
  const approverDenierMessage = get(course, 'userCourse.approverDenierMessage');

  return (
    <Dialog
      open={Boolean(course)}
      {...rest}
      onClose={closeFileUploader}
      maxWidth='md'
    >
      <DialogTitle className="pb-2"><b className="text-2xl">Upload your proof of payment</b></DialogTitle>

      <Formik
        initialValues={{
          receiptAmountpaid: '',
          receiptComment: '',
          receiptImage: null,
        }}
        validationSchema={VALIDATION}
        onSubmit={async (variables, { setSubmitting }) => {
          try {
            await mutate({ variables: {
              ...variables,
              courseId: Number(course.id),
            }});
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ submitForm, setFieldValue, isSubmitting, isValid }) => (<>
          <DialogContent>
            <p className="mb-5 text-sm">Kindly upload a screenshot of your payment for any of the following payment channels to confirm your course enrollment. You will receive a notification once your course materials are available.</p>
            <Form>
              <Grid container item>
                <Grid container classes={{ root: classes.paddingBottom }}>
                  <Typography variant='h6'>Payment Amount: <B>Php 6,500.00</B></Typography>
                </Grid>

                <Grid container item spacing={4} classes={{ root: classes.paddingBottom }}>
                  <PaymentOption img='bdo.jpg' name='BDO Peso Savings' info={[
                    { label: 'Account Name:', value: 'Nereus Maritime Review Center' },
                    { label: 'Account No:', value: '005280196437' },
                  ]}/>

                  <PaymentOption img='gcash.jpg' name='G-Cash' info='09454735366' />

                  <PaymentOption img='paymaya.jpg' name='PayMaya' info='09683585228' />
                </Grid>

                {
                  wasRejected && (
                    <Grid container item spacing={2} className="bg-red-200 rounded mx-1 mb-3 p-4" classes={{ root: classes.paddingBottom }}>
                      <Typography variant='h7'><b>NOTE:</b> Your payment has been <b>REJECTED</b> due to the following reason: <b>{approverDenierMessage}</b> <br/><br/> Please review your transaction and re-upload your proof of payment again.</Typography>
                    </Grid>
                  )
                }

                <Grid container className="pb-2">
                  <Field
                    name='receiptAmountpaid'
                    label='Amount Paid'
                    fullWidth
                    component={TextField}
                    variant='outlined'
                  />
                </Grid>

                <Grid container>
                  <Field
                    name='receiptComment'
                    label='Notes'
                    fullWidth
                    component={TextField}
                    variant='outlined'
                  />
                </Grid>

                <Grid container className="pb-8 pt-2">
                  <Field name='receiptImage'>
                    {({ field: { name } }) => (
                      <Dropzone name={name} setFieldValue={setFieldValue} />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Form>
          </DialogContent>

          <DialogActions className="py-4">
            <Button disabled={isSubmitting} onClick={closeFileUploader}>
              Cancel
            </Button>

            <Button
              onClick={submitForm}
              disabled={!isValid || isSubmitting}
              variant='contained'
              color='primary'
              autoFocus
            >
              Submit
            </Button>
          </DialogActions>
        </>)}
      </Formik>
    </Dialog>
  )
}

FileUploaderDialog.propTypes = {
  setCourseUploadingFor: PropTypes.func.isRequired,
  course: PropTypes.object,
}

export default memo(FileUploaderDialog);
