import React, { memo } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: 'url("/images/main_background.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    maxWidth: '100%',
    height: 'auto',
    overflow: 'hidden',
  }
}));

// TODO Use this in more places than just sidebar:
const BackgroundImageWithImage = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.background, className)} {...rest}>
      <img
        className={classes.logo}
        src='/images/header-logo.png'
        alt='header-logo'
      />
    </div>
  )
}

export default memo(BackgroundImageWithImage);
