import React, {} from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { PASSING_PERCENT } from '@nereus/shared/constants';
// MUI
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, styled } from '@material-ui/core/styles';
// Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

const ScoreTypograph = styled(Typography)(({theme, ...props}) => ({
  display: 'flex',
  justifyContent: 'space-between'
}))
const useStyles = makeStyles((theme) => ({
  check: {
    color: theme.palette.success.main
  },
  wrong: {
    color: theme.palette.error.main
  },
  passIcon: {
    fontSize: '2em',
    color: theme.palette.success.main
  },
  failIcon: {
    fontSize: '1em',
    color: theme.palette.error.main
  }
}))


function ExamPageResult ({
  exam,
  result,
  totalQuestions
}) {
  const history = useHistory()
  const classes = useStyles()
  const courseName = get(exam, 'exam.courseCategory.course.name', '')
  const functionName = get(exam, 'exam.name', '')
  const numberOfCorrect = get(result, 'score', 0)
  const numberOfMistake = totalQuestions - numberOfCorrect
  const percentageCorrect = ((numberOfCorrect / totalQuestions) * 100).toFixed(2);
  const isPassed = percentageCorrect >= PASSING_PERCENT.exam
  // Navigate to exam-list
  const hadnleBack = () => {
    
    history.replace('/exam-list')
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" align="center">{courseName}</Typography>
        <Typography variant="h4" align="center">{functionName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" align="center">Exam Results</Typography>
      </Grid>
      <Grid item sm={3}>
      </Grid>
      <Grid item sm={6}>
        <Paper elevation={6} style={{padding: 40}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {
                isPassed && (
                  <Typography align="center" variant="h2"><CheckCircleIcon className={classes.passIcon} /></Typography>
                )
              }

              
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" align="center"><CheckCircleOutlineIcon className={classes.check}/></Typography>
            </Grid>
            <Grid item xs={6}>
              <ScoreTypograph variant="h5" align="center">
                <span>Correct</span>
                <span>{numberOfCorrect}</span>
              </ScoreTypograph>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" align="center"><HighlightOffIcon className={classes.wrong}/></Typography>
            </Grid>
            <Grid item xs={6}>
              <ScoreTypograph variant="h5" align="center">
                <span>Mistakes</span>
                <span>{numberOfMistake}</span>
              </ScoreTypograph>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" align="center"><FormatListNumberedIcon /></Typography>
            </Grid>
            <Grid item xs={6}>
              <ScoreTypograph variant="h5" align="center">
                <span>Total Items</span>
                <span>{totalQuestions}</span>
              </ScoreTypograph>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" align="center"><TrackChangesIcon /></Typography>
            </Grid>
            <Grid item xs={6}>
              <ScoreTypograph variant="h5" align="center">
                <span>Grade</span>
                <span>{percentageCorrect}%</span>
              </ScoreTypograph>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item sm={3}>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Box style={{width: 200}}>
            <Button variant="contained" color="primary" size="large" fullWidth onClick={hadnleBack}>GO BACK EXAM LIST</Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
ExamPageResult.propTypes = {
  exam: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  totalQuestions: PropTypes.number.isRequired
}

export default ExamPageResult