import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import Exam from 'components/common/Exam';
import Loading from 'components/common/Loading';
import { BEGIN_EXAM_TRIAL } from 'constants/queries';
import { performChecking, useQuery } from 'utils';

const TrialExamOrReviewPage = ({
  examKey,
  query,
  queryVariables,
  queryName,
  performLiveChecking,
  ResultsFooter,
}) => {
  const { data, LoadingProps } = useQuery(query, {
    variables: queryVariables,
  });

  const questions = get(data, `${queryName}.questions`, []);
  const examName = get(data, `${queryName}.name`, 'Exam');
  console.log('quest\n'+questions);
  console.log('examname\n'+examName);
  console.log('examkey\n'+examKey);
  const handleSubmit = args => {
    return Object.keys(performChecking(args)).length
  }

  return (
    <Loading {...LoadingProps}>
      <Exam
        examKey={examKey}
        questions={questions}
        examName={examName}
        liveChecking={performLiveChecking ? performChecking : undefined}
        onSubmit={handleSubmit}
        ResultsFooter={ResultsFooter}
      />
    </Loading>
  )
}

TrialExamOrReviewPage.propTypes = {
  examKey: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  queryVariables: PropTypes.object,
  queryName: PropTypes.string,
  liveChecking: PropTypes.bool,
  Footer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
}

TrialExamOrReviewPage.defaultProps = {
  query: BEGIN_EXAM_TRIAL,
  queryVariables: {},
  queryName: 'beginExamTrial',
  liveChecking: false,
}

export default memo(TrialExamOrReviewPage);
