import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
// Material
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { USERS as QUERY } from 'constants/queries';
import Table from 'components/common/Table';
import Loading from 'components/common/Loading';
import Details from './Details';
import { useLazyQuery } from '@apollo/client'
import { displayDate, useQuery } from 'utils';

const d = x => {
  return displayDate(get(x, 'value', x))
}

const getFullName = row => {
  const firstName = get(row, 'firstName', null);
  const middleName = get(row, 'middleName', null);
  const lastName = get(row, 'lastName', null);
  
  return [
    firstName,
    middleName,
    lastName,
  ].filter(x => x).join(' ');
}

const Users = ({ disablePagination, ...rest }) => {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);

  const [getUsers, {}] = useLazyQuery(QUERY, {
    onCompleted(data) {
      setRows(data.users)
    }
  })
  // const { data, LoadingProps } = useQuery(QUERY, {
  //   enqueueSnackbar,
  //   errorMessage: 'Error loading list of users',
  // });
  
  // const rows = get(data, 'users', []);
  
  const [debounce, setDebounce] = useState(null)
  const [keyword, setKeyword] = useState('')

  const handleSearch = (event) => {
    const value = event.target.value

    setKeyword(value)
    if(Boolean(debounce)) {
      clearTimeout(debounce)
    }

    setDebounce(setTimeout(() => {
      getUsers({
        variables: {
          search: value
        }
      })
    }, 300))
  }

  //Tried DataGrid, did not like it, keeping it here just in case for now:
  // return (
  //   <div style={{ height: '100%', width: '100%' }}>
  //     <DataGrid rows={rows} pageSize={10} columns={[
  //       { field: 'username', headerName: 'Username' },
  //       { field: 'email', headerName: 'Email' },
  //       { field: 'firstName', headerName: 'First Name', width: 200 },
  //       { field: 'middleName', headerName: 'Middle Name' },
  //       { field: 'lastName', headerName: 'Last Name' },
  //       { field: 'graduatedFrom', headerName: 'Graduated From' },
  //       { field: 'graduatedOn', headerName: 'Graduated On', valueFormatter: d },
  //       { field: 'birthday', headerName: 'Birthday', valueFormatter: d },
  //       { field: 'address', headerName: 'Address' },
  //       { field: 'phoneNumber', headerName: 'Phone' },
  //       { field: 'company', headerName: 'Company' },
  //       { field: 'referredBy', headerName: 'Referred By' },
  //       { field: 'rank', headerName: 'Rank', valueGetter: params => get(params.value, 'name', '') },
  //       { field: 'isAdmin', headerName: 'Admin?', valueFormatter: x => x ? 'yes' : 'no' },
  //       { field: 'Created On', headerName: 'createdOn', valueFormatter: d },
  //     ]}/>
  //   </div>
  // )

  // username, email, full name, rank, phone, date registered
  useEffect(() => {
    getUsers()
  }, [])
  return (
    <Loading loading={false}>
      <Grid container spacing={2} style={{marginTop: theme.spacing(1)}}>
        <Grid item xs={12}>
          <TextField
            size="small"
            variant="outlined"
            label="Search. . ."
            fullWidth
            value={keyword}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            rows={rows}
            columns={[
              { field: 'username' },
              { field: 'email' },
              { label: 'Name', get: getFullName },
              { label: 'Rank', get: row => get(row, 'rank.name', '') },
              { field: 'phoneNumber', label: 'Phone' },
              { field: 'referredBy', label: 'Reffered By' },
              { field: 'createdOn', label: 'Date Registered', format: d },
            ]}
            renderRowExpand={disablePagination ? null : Details}
            disablePagination={disablePagination}
            {...rest}
          />
        </Grid>
      </Grid>
    </Loading>
  )
}

Users.propTypes = {
  disablePagination: PropTypes.bool,
}

Users.defaultProps = {
  disablePagination: false,
}

export default memo(Users);
