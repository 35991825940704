import React, { createContext } from 'react';
import { assign } from 'xstate'
import { useInterpret } from '@xstate/react';
import ManageCourseMachine from './machines/manage-course.machine'
import CourseMachine from './machines/course.machine'
import FunctionsMachine from './machines/functions.machine'
import ExamMachine from './machines/exam.machine'
import ReviewMachine from './machines/review.machine'
import TrialExamMachine from './machines/trial-exam.machine'

export const GlobalStateContext = createContext({});

export const GlobalStateProvider = (props) => {
  const ManageCourseService = useInterpret(ManageCourseMachine)
  const CourseService = useInterpret(CourseMachine)
  const FunctionsService = useInterpret(FunctionsMachine)
  const ExamService = useInterpret(ExamMachine)
  const ReviewService = useInterpret(ReviewMachine)
  const TrialExamService = useInterpret(TrialExamMachine)
  return (
    <GlobalStateContext.Provider value={{ ManageCourseService, CourseService, FunctionsService, ExamService, ReviewService, TrialExamService }}>
      {props.children}
    </GlobalStateContext.Provider>
  )
}

