import { createMachine, assign } from 'xstate'

export default createMachine({
  id: 'TrialExam',
  initial: 'idle',
  context: {
    course: '',
    function: '',
    questions: [],
    answered: [],
    page: 1,
    result: {
      items: 0,
      correct: 0,
      wrong: 0,
      grade: 0.00
    }
  },
  states: {
    idle: {
      on: {
        FETCH: 'fetch.process',
        PAGE: {
          actions: ['setPage']
        },
        ANSWERED_QUESTION: {
          actions: ['setAnswered']
        },
        SUBMIT: 'submit.process'
      }
    },
    fetch: {
      states: {
        process: {
          on: {
            SUCCESS: {
              target: '#TrialExam.idle',
              actions: ['setQuestionsAndName']
            },
            FAILURE: 'failure'
          }
        },
        failure: {
          on: {
            RETRY: 'process'
          }
        },
      }
    },
    submit: {
      states: {
        process: {
          on: {
            SUCCESS: {
              target: 'success',
              actions: ['setResult']
            },
            FAILURE: 'failure'
          }
        },
        success: {
          on: {
            FETCH: '#TrialExam.fetch.process'
          }
        },
        failure: {
          on: {
            SUBMIT: 'process'
          }
        },
      }
    }
  }
}, {
  actions: {
    setResult: assign({
      result(ctx, event) {
        const data = event.data
        const items = ctx.questions.length
        const correct = data.correct
        const wrong = items - correct
        const grade = ((correct / items) * 100).toFixed(2)

        return {
          items,
          correct,
          wrong,
          grade
        }
      }
    }),
    setAnswered: assign({
      answered(ctx, event) {
        const data = event.data
        const answers = [...ctx.answered]
        let found = false
        for(let i=0; i < answers.length; i++) {
          const ansered = answers[i]
          if(ansered.id == data.id) {
            found = true
            answers[i] = {...data}
            break;
          }
        }
        if(!found) {
          answers.push(data)
        }
        return answers
      }
    }),
    setPage: assign({
      page(ctx, event) {
        return event.data
      }
    }),
    setQuestionsAndName: assign((ctx, event) => {
      const data = event.data
      const course = data.course
      const _function = data.function
      const questions = data.questions
      return {
        ...ctx,
        page: 1,
        course,
        function: _function,
        questions
      }
    })
  }
})