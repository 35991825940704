import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Grid, Typography, DialogTitle as MuiDialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Courses from 'components/common/Courses';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: 32,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ServicesOfferedDialog = ({
  isOpen,
  closeModal,
  courses,
  ...rest
}) => {
  const classes = useStyles();
  const fetchPolicy = courses && courses.length > 0
    ? 'cache-first'
    : 'cache-and-network';

  const DialogTitle = ((props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
  return (
    <Dialog
      open={isOpen}
      maxWidth='lg'
      {...rest}
      onClose={closeModal}
    >
      <DialogTitle id="customized-dialog-title" onClose={closeModal}>
        Course Outline
      </DialogTitle>
      
      <Grid container item classes={{ root: classes.grid }}>
        <Courses
          courses={courses}
          queryOptions={{ fetchPolicy }}
          hideHeader={true}
          startExpanded={true}
          disableExpandCollapse={true}
          hideActionButtons={true}
          hideTakeExamButtons={true}
          showCategoryItems={true}
          showExamButtonAsDisabledIfNotAccepted={true}
        />
      </Grid>
    </Dialog>
  )
}

ServicesOfferedDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  courses: PropTypes.arrayOf(PropTypes.object),
}

export default memo(ServicesOfferedDialog)
