import React, {} from 'react'
import PropTypes from 'prop-types'
// Material
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

function InfoDialog({
  open,
  data,
  onClose
}) {

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Course Detail</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography>Code</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{data?.code}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Name</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{data?.name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Service label</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{data?.servicesOfferedLabel || '(not defined)'}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Description</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography>{data?.description || '(not defined)'}</Typography>
          </Grid>
          {
            (data?.categories || []).map((func, index) => (
              <Grid item xs={12} key={'function-row-'+func.id}>
                <Typography>{func?.name}</Typography>
                {
                  func.items.length > 0 && (
                    <ul>
                      {
                        func.items.map((competence) => (
                          <li key={'competence-list-'+competence.id}><Typography>{competence.name}</Typography></li>
                        ))
                      }
                    </ul>
                  )
                }
              </Grid>
            ))
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

InfoDialog.propTypes = {
  open: PropTypes.func,
  data: PropTypes.object,
  onClose: PropTypes.func
}

export default InfoDialog