import React, {} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import {Button as MUIButton} from '@material-ui/core';

const Button = styled(MUIButton)(({theme, ...props}) => {
  if(props.color == 'success') {
    return {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white
    }
  }
  if(props.color == 'error') {
    return {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white
    }
  }
  if(props.color == 'primary') {
    return {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  }
})


export default Button