import React, { memo, useEffect } from 'react';
import { Switch, Link, Redirect } from 'react-router-dom';
// eslint-disable-next-line
import { Grid } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Route as RouteBase } from 'react-router-dom';
import { get, noop } from 'lodash';
import 'fontsource-roboto';
import { MINUTES_TO_UPDATE_IP_TABLE } from '@nereus/shared/constants'

import { LOG_SITE_VISIT } from 'constants/mutations';
import { setSiteVisitors } from 'reduxConfig/actions/misc';
import { updateUser } from 'reduxConfig/actions/currentUser';
import { checkIfAdmin, checkIfShowCoursesInExamMode } from 'reduxConfig/selectors';
import { checkInDevMode, useMutation } from 'utils';
import Route, { PublicRoute, PrivateRoute, AdminRoute } from './Route';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ServicesPage from './pages/ServicesPage';
import ContactPage from './pages/ContactPage';
import CoursesPage from './pages/CoursesPage';
import CourseImportPage from './pages/dev/CourseImportPage';
import CoursePage from './pages/dev/CoursePage';
import FunctionQuestionsPage from './pages/dev/FunctionQuestionsPage';
import CoursesTakeExamPage from './pages/CoursesTakeExamPage';
import LoginPage from './pages/LoginRegisterProfile/LoginPage';
import RegisterPage from './pages/LoginRegisterProfile/RegisterPage';
import ProfileUpdatePage from './pages/LoginRegisterProfile/ProfileUpdatePage';
import AdminDashboardPage from './pages/AdminDashboardPage';
import CourseApprovalsPage from './pages/CourseApprovalsPage';
import UsersPage from './pages/UsersPage';
import ReportPage from './pages/ReportPage';
import LogoutPage from './pages/LogoutPage';
import BlankPage from './pages/BlankPage';
import ExamListPage from './pages/ExamListPage';
import ExamPage from './pages/ExamPage';
import {
  TrialExamPage,
  // ReviewPage,
} from './pages/TrialExamOrReviewPage';
import ReviewPage from './pages/ReviewPage'
import TrialPage from './pages/TrialPage'
import { version as clientVersion } from '../../package.json';
import { useLazyQuery } from '@apollo/client'
import { GET_USER_TAKING_EXAM_FOR_COURSE_ID as CURRENT_USER } from '../constants/queries'

// States
import { GlobalStateProvider } from './global.state'

// const MINUTES_TO_UPDATE_IP_TABLE = 10;

const theme = createMuiTheme({
  // palette: {
  //   secondary: {
  //     main: orange[500],
  //   },
  // },
});


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    flex: 1,
  },
  red: { backgroundColor: 'red', height: 42 },
  blue: { backgroundColor: 'blue' },
  green: { backgroundColor: 'green' },
}));

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAdmin = useSelector(checkIfAdmin);
  const showCoursesInExamMode = useSelector(checkIfShowCoursesInExamMode);
  const [updateUnitRecord] = useLazyQuery(CURRENT_USER)
  const [logSiteVisit] = useMutation(LOG_SITE_VISIT, {
    onCompleted: data => {
      // TODO Replace various uses of get() with ?.
      const {
        serverVersion,
        serverEnv,
        count = 0,
        user,
      } = get(data, 'logSiteVisit', {});
      const clientEnv = process.env.NODE_ENV;

      if (checkInDevMode() || isAdmin) {
        console.log({
          clientVersion,
          clientEnv,
          serverVersion,
          serverEnv,
          user,
        });

        if (!serverEnv) {
          console.warn(`The server's NODE_ENV is not set, this should act as "development"`);
        }
      }

      dispatch(setSiteVisitors(count));

      if (user) dispatch(updateUser(user))
    },
  });
  useEffect(() => { logSiteVisit(); return noop; }, [logSiteVisit]);
  useEffect(() => {
    const interval = setInterval(() => {
      // console.log('Must trigger update current user browser credentials')
      updateUnitRecord()
    }, (((MINUTES_TO_UPDATE_IP_TABLE || 10) * 60) * 1000))
  }, [])
  // This was used to test a fix for the layout.
  // Keeping it for now in case needed again.
  // return (
  //   <div className={classes.mainContainer}>
  //     <div className={classes.red}>RED</div>
  //     <Grid item container xs={12} classes={{ root: classes.contentContainer }}>
  //       <Grid item container classes={{container:classes.blue}} xs={3}>BLUE</Grid>
  //       <Grid item container classes={{container:classes.green}} xs={9}>GREEN</Grid>
  //     </Grid>
  //   </div>
  // )

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.mainContainer}>
        <GlobalStateProvider>
          <Switch>
            <PublicRoute exact path='/' component={HomePage} isHomePage={true} />
            <PublicRoute exact path='/services' component={ServicesPage} />
            <PublicRoute exact path='/contact' component={ContactPage} />
            <PublicRoute exact path='/login' component={LoginPage} />
            <PublicRoute exact path='/register' component={RegisterPage} />
            <PublicRoute exact path='/about' component={AboutPage} />

            <PrivateRoute exact path='/courses' component={CoursesPage} examMode={false} skipExamModeCheck={showCoursesInExamMode} />
            <PrivateRoute exact path='/course-exam-list' component={CoursesTakeExamPage} examMode={false} />
            <PrivateRoute exact path='/exam-list' component={ExamListPage} examMode={true} />
            {/* DEPRECATED: <PrivateRoute exact path='' component={ExamListPage} examMode={true} /> */}
            <Redirect from='/exam-list/:courseId' to='/exam-list' />
            <PrivateRoute exact path='/profile-edit' component={ProfileUpdatePage} />

            <AdminRoute exact path='/admin/dashboard' component={AdminDashboardPage} />
            <AdminRoute exact path='/admin/approve-deny-payments' component={CourseApprovalsPage} />
            <AdminRoute exact path='/dev/courses' component={CourseImportPage} />
            <AdminRoute exact path='/dev/course/:courseId' component={CoursePage} />
            <AdminRoute exact path='/dev/course/function/:functionId' component={FunctionQuestionsPage} />
            <AdminRoute exact path='/admin/users' component={UsersPage} />
            <AdminRoute exact path='/admin/report' component={ReportPage} sidebar={false} />

            // <Route path='/exam-trial/:examId' component={TrialExamPage} examMode={false} />
            <Route path='/exam-trials/:examId' component={TrialPage} examMode={false} />
            <PrivateRoute path='/review/:courseItemId' component={ReviewPage} examMode={false} />
            <PrivateRoute path='/exam/:examId' component={ExamPage} examMode={true} />
            
            <RouteBase exact path='/logout' component={LogoutPage} />

            <Route exact path='/blank' component={BlankPage} />
            <RouteBase exact path='/very-blank' component={BlankPage} />

            {/* TEMP Disabled automatic redirect on 404 */}
            {/* <Redirect to ='/' /> */}
            <RouteBase>
              <div>Page not found. Pls click <Link to='/'>here</Link></div>
            </RouteBase>
          </Switch>
        </GlobalStateProvider>
      </div>
    </ThemeProvider>
  )
}

export default memo(App);
