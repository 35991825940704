import React, {} from 'react'
import PropTypes from 'prop-types'
// MUI
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';

function Loading() {
  const theme = useTheme()
  return (
    <Paper elevation={5} style={{width: "100%", padding: theme.spacing(3)}}>
      <Typography align="center" variant="h4">
        <CircularProgress />
      </Typography>
      <Typography align="center">
        Preparing . . .
      </Typography>
    </Paper>
  )
}
Loading.propTypes = {
  //
}

export default Loading