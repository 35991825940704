import { createMachine, assign } from 'xstate'

export default createMachine({
  id: 'Functions',
  initial: 'idle',
  context: {
    selectedQuestion: null,
    rowsPerPage: 10,
    page: 1,
    detail: null,
    competences: [],
    questions: [],
    totalQuestions: 0,
    filteredQuestions: [],
    filter: {
      search: '',
      filter: '',
      compentence: ''
    }
  },
  states: {
    idle: {
      on: {
        FETCH: 'fetch.process',
        SEARCHING: 'search.processing',
        SET_FILTER: {
          actions: ['setFilter']
        },
        SET_PAGE: {
          actions: ['setPage']
        },
        SET_ROW_PER_PAGE: {
          actions: ['setRowPage']
        },
        EDIT: {
          target: 'edit.show',
          actions: ['setSelectedQuestions']
        },
        RETIRE: {
          target: 'retire.show',
          actions: ['setSelectedQuestions']
        }
      }  
    },
    search: {
      states: {
        processing: {
          on: {
            SUCCESS: {
              target: '#Functions.idle',
              actions: ['setQuestions']
            },
            FAILURE: 'failure'
          }
        },
        failure: {},
      }
    },
    fetch: {
      states: {
        process: {
          on: {
            SUCCESS: {
              target: 'success',
              actions: ['setData']
            },
            FAILURE: 'failure'
          }
        },
        success: {
          on: {
            CLOSE: '#Functions.idle'
          }
        },
        failure: {
          on: {
            RETRY: '#Functions.fetch.process'
          }
        },
      }
    },
    edit: {
      states: {
        show: {
          on: {
            SAVE: 'process',
            CANCEL: '#Functions.idle'
          }
        },
        process: {
          // invoke: {
          //   src: 'updating',
          //   onDone: 'success',
          //   onError: 'failure'
          // }
          on: {
            SUCCESS: {
              target: 'success',
              actions: ['updateQuestion']
            },
            FAILURE: 'failure'
          }
        },
        success: {
          on: {
            CLOSE: '#Functions.idle'
          }
        },
        failure: {
          on: {
            CLOSE: '#Functions.idle'
          }
        }
      }
    },
    retire: {
      states: {
        show: {
          on: {
            CONTINUE: 'process',
            CANCEL: '#Functions.idle'
          }
        },
        process: {
          on: {
            SUCCESS: {
              target: 'success',
              actions: ['updateQuestion', 'filterQuestions']
            },
            FAILURE: 'failure'
          }
        },
        success: {
          on: {
            CLOSE: '#Functions.idle'
          }
        },
        failure: {
          on: {
            CLOSE: '#Functions.idle'
          }
        },
      }
    }
  }
}, {
  actions: {
    setQuestions: assign({
      totalQuestions: (ctx, event) => event.data.detail.questions_count,
      questions: (ctx, event) => [...event.data.questions]
    }),
    updateQuestion: assign({
      questions: (ctx, event) => {
        const newQuestion = event.data
        const newList = [...ctx.questions]
        for(let i = 0; i < newList.length; i++) {
          const q = newList[i];
          if(q.id == newQuestion.id) {
            newList[i] = {...newQuestion}
            break;
          }
        }
        return newList
      }
    }),
    setSelectedQuestions: assign({
      selectedQuestion: (ctx, event) => event.data
    }),
    setRowPage: assign({
      page: 0,
      rowsPerPage: (ctx, event) => {
        console.log(event.data)
        return event.data
      }
    }),
    setPage: assign({
      page: (ctx, event) => {
        return event.data
      }
    }),
    // filterQuestions: assign({
    //   page: 0,
    //   filteredQuestions: (ctx, event) => {
    //     const filter = ctx.filter
    //     let newList = [...ctx.questions]
    //     if(Boolean(filter.search) || Boolean(filter.filter) || Boolean(filter.compentence)) {
    //       newList = newList.filter((item) => {
    //         if(Boolean(filter.search)) {
    //           const keyword = filter.search.toLowerCase()
    //           if(keyword.length > 1) {
    //             const string = [item.text, item.choices.map(c => c.text).join('|'), item.courseItem.name].join('|').toLowerCase()
    //             if(string.indexOf(keyword) === -1) {
    //               return false
    //             }
    //           }
    //         }
    //         if(Boolean(filter.filter)) {
    //           if(filter.filter == 'retired' && !item.isRetired) {
    //             return false
    //           }
    //           if(filter.filter == 'active' && item.isRetired) {
    //             return false
    //           }
    //         }
    //         if(filter.compentence != 'all') {
    //           if(Number(filter.compentence) != item.courseItem.id) {
    //             return false
    //           }
    //         }
    //         return true
    //       })
    //     }
    //     return newList
    //   }
    // }),
    setFilter: assign({
      filter: (ctx, event) => {
        return {
          ...ctx.filter,
          ...event.data
        }
      }
    }),
    setData: assign((ctx, event) => {
      const data = event.data
      return {
        ...ctx,
        totalQuestions: data.detail.questions_count,
        detail: data.detail,
        competences: data.competences,
        questions: data.questions
        // questions: []
      }
    })
  },
  services: {
    updating(ctx, event) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true)
        }, 3000)
      })
    }
  }
})