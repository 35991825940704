import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { PASSING_PERCENT } from '@nereus/shared/constants';
import { useGlobalDialog } from 'globalDialog';
import {
  Actions as ConfirmTakeExamActions,
  Title as ConfirmTakeExamTitle,
} from 'components/common/Courses/Course/TakeExamsButton'; // TODO may need to move this
import Paragraphs from 'components/common/Paragraphs';
import {
  SCORE_XS,
  ATTEMPTS_XS,
  PERCENT_XS,
  BUTTON_XS,
} from './constants';
import PercentDisplay from './PercentDisplay';

const CONFIRM_TAKE_EXAM_MESSAGE = [
  'Please proceed with caution. Once you choose to take this part of the exam,  you will not be able to go back unless you submit your answers.',
  'By clicking to proceed, you agree that you have read the information above and you are aware of the possible outcomes for continuing.',
];

const Exam = ({
  exams,
  maxExamAttempts,
  mostAttemptedExamCount,
  allExamsHaveSameAttempts,
}) => {
  const history = useHistory();
  const {
    open: openDialog,
    close: closeDialog,
  } = useGlobalDialog();

  if (exams.length <= 0) return null;

  const onlyOneExam = exams.length === 1;

  const handleClick = examId => {
    openDialog({
      message: <Paragraphs data={CONFIRM_TAKE_EXAM_MESSAGE} />,
      title: <ConfirmTakeExamTitle />,
      actions: (
        <ConfirmTakeExamActions
          takeExam={() => history.push(`/exam/${examId}`)}
          closeDialog={closeDialog}
          confirmText='PROCEED'
          cancelText='CANCEL'
        />
      ),
    });
  }

  return exams.map(exam => {
    let attemptsText = `${exam.attempts}`;
    let noMoreAttempts = false;

    if (maxExamAttempts !== null) {
      attemptsText = `${exam.attempts}/${maxExamAttempts}`;
      
      if (exam.attempts >= maxExamAttempts) {
        noMoreAttempts = true;
      }
    }

    if (mostAttemptedExamCount > 0 && !allExamsHaveSameAttempts && exam.attempts >= mostAttemptedExamCount) {
      noMoreAttempts = true;
    }

    let scoreText = '';
    let percent = null;
    if (exam.score !== null) {
      scoreText = `${exam.score}/${exam.numberOfQuestions}`;
      percent = (exam.score/exam.numberOfQuestions) * 100;
    }

    return (
      <Grid item container key={exam.id}>
        <Grid item xs={SCORE_XS}>
          {scoreText ? scoreText : 'N/A'}
        </Grid>

        <Grid item xs={PERCENT_XS}>
          { percent != null ? <PercentDisplay percent={percent} passingPercent={PASSING_PERCENT.exam} /> : 'N/A' }
        </Grid>

        <Grid item xs={ATTEMPTS_XS}>
          {attemptsText}
        </Grid>
        
        <Grid item xs={BUTTON_XS}>
          <Button
            variant='contained'
            className={!noMoreAttempts ? 'bg-green-400 text-white' : 'bg-gray'}
            onClick={() => handleClick(exam.id)}
            disabled={noMoreAttempts}
          >
            {onlyOneExam ? 'Take Exam' : exam.name}
          </Button>
        </Grid>
      </Grid>
    )
  });
}

Exam.propTypes = {
  exams: PropTypes.array,
  maxExamAttempts: PropTypes.number,
  mostAttemptedExamCount: PropTypes.number,
  allExamsHaveSameAttempts: PropTypes.bool,
}

Exam.defaultProps = {
  exams: [],
  maxExamAttempts: null,
  mostAttemptedExamCount: 0,
  allExamsHaveSameAttempts: false,
}

export default memo(Exam);
