import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Route as RouteBase } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

import ExamMode from 'components/common/ExamMode';
import Header from './Header';
import Sidebar from './Sidebar';
import Context from './context';

const SIDEBAR_XS = 3;

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    flex: 1,
  },
  contentContainerPrinting: {
    '@media screen': {
      display: 'none',
    },
  },
  allowBackgroundInPrint: {
    '@media print': {
      colorAdjust: 'exact !important',
    },
  },
  testRed: {
    backgroundColor: 'red',
  },
}));

const Route = ({
  component: Component,
  header,
  noHeader,
  sidebar,
  noSidebar,
  examMode: requiredExamMode,
  skipExamModeCheck,
  examRedirect,
  isHomePage,
  xs,
  ...rest
}) => {
  const classes = useStyles();
  const contentRef = useRef();
  const [isPrinting, setPrinting] = useState(false)
  const onBeforeGetContent = () => setPrinting(true);
  const onAfterPrint = () => setPrinting(false);

  if (noHeader) header = false;
  if (noSidebar) sidebar = false;

  const componentWrapperXs = xs - Number(!isPrinting && sidebar && SIDEBAR_XS);

  return (
    <ExamMode.Loading
      requiredExamMode={requiredExamMode}
      skipExamModeCheck={skipExamModeCheck}
      examRedirect={examRedirect}
    >
      <RouteBase
        {...rest}
        render={props => (<>
          { header && <Header isHomePage={isHomePage} /> }

          <Grid
            container
            item
            classes={{ root: [
              classes.contentContainer,
              isPrinting ? classes.contentContainerPrinting : null,
            ].filter(x => x).join(' ')}}
          >
            { !isPrinting && sidebar && (
              <Grid container item xs={SIDEBAR_XS}>
                <Sidebar />
              </Grid>
            )}

            <Grid
              id='component-wrapper'
              ref={contentRef}
              container
              item
              xs={componentWrapperXs}
              justify='center'
              alignContent='flex-start'
              classes={{ root: [
                'bg-gray-200',
                // classes.allowBackgroundInPrint,
                // (sidebar ? `px-16 py-20` : ''),
              ].filter(x => x).join(' ')}}
            >
              <Context.Provider value={{
                ref: get(contentRef, 'current', null),
                onBeforeGetContent,
                onAfterPrint,
                isPrinting,
              }}>
                <Component {...props} />
              </Context.Provider>
            </Grid>
          </Grid>
        </>)}
      />
    </ExamMode.Loading>
  )
}

Route.propTypes = {
  header: PropTypes.bool,
  sidebar: PropTypes.bool,
  noHeader: PropTypes.bool,
  noSidebar: PropTypes.bool,
  examMode: PropTypes.bool,
  skipExamModeCheck: PropTypes.bool,
  examRedirect: PropTypes.string,
  isHomePage: PropTypes.bool,
  xs: PropTypes.number,
}

Route.defaultProps = {
  header: true,
  sidebar: false,
  isHomePage: false,
  xs: 12,
}

export default Route;
