import { createMachine, assign } from 'xstate'

export default createMachine({
  id: 'Exam',
  initial: 'inactive',
  type: 'parallel',
  context: {
    exam: null,
    error: null,
    question: null,
    result: null,
    page: 1,
    errorMessage: ''
  },
  states: {
    inactive: {
      on: {
        BEGIN_EXAM: 'begin.process'
      }
    },
    begin: {
      id: 'Begin',
      initial: 'inactive',
      states: {
        inactive: {},
        process: {
          on: {
            SUCCESS: {
              target: 'success',
              actions: ['setExam', 'resetPage']
            },
            FAILURE: {
              target: 'failure',
              actions: ['setError', 'setErrorMessage']
            }
          }
        },
        success: {
          on: {
            QUESTION: {
              actions: ['setQuestion', 'callback']
            },
            PAGE: {
              actions: ['setPage']
            },
            INACTIVE: '#Begin.inactive',
            SUBMIT: '#Exam.submit.confirm',
            INVALID: '#Exam.submit.invalid',
            NOT_ALL_ANSWERED: '#Exam.submit.notAllAnswered'
          }
        },
        failure: {
          on: {
            RETRY: 'process'
          }
        },
      }
    },
    submit: {
      id: 'Submit',
      initial: 'inactive',
      states: {
        inactive: {},
        confirm: {
          on: {
            CONFIRM: 'process',
            CANCEL: 'inactive'
          }
        },
        notAllAnswered: {
          on: {
            CONFIRM: 'process',
            CANCEL: 'inactive',
          }
        },
        process: {
          on: {
            SUCCESS: {
              target: 'success',
              actions: ['setResult']
            },
            INVALID: 'invalid',
            FAILURE: {
              target: 'failure',
              actions: ['setErrorMessage']
            }
          }
        },
        success: {
          on: {
            INACTIVE: 'inactive'
          }
        },
        invalid: {
          on: {
            CLOSE: 'inactive',
            RETRY: 'process'
          }
        },
        failure: {
          on: {
            CLOSE: 'inactive',
            RETRY: 'process'
          }
        },
      }
    }
  }
}, {
  actions: {
    resetPage: assign({
      page: 1
    }),
    setResult: assign({
      result(ctx, event) {
        return event.data
      }
    }),
    setErrorMessage: assign({
      errorMessage(ctx, event) {
        return event.data.message
      }
    }),
    callback(ctx, event) {
      if(Boolean(event.data.callback)) {
        event.data.callback()
      }
    },
    setPage: assign({
      page(ctx, event) {
        return event.data
      }
    }),
    setQuestion: assign({
      question(ctx, event) {
        const {callback, ...data} = event.data
        return {...data, startTime: new Date()}
      }
    }),
    setError: assign({
      error(ctx, event) {
        return event.data
      }
    }),
    setExam: assign({
      exam(ctx, event) {
        return event.data
      }
    })
  }
})