import { createMachine, assign } from 'xstate'
import { useLazyQuery } from '@apollo/client';
import { COURSES } from '../../constants/queries'

const fetchState = {
  states: {
    process: {
      on: {
        SUCCESS: {
          target: 'success',
          actions: ['setList']
        },
        FAILURE: 'failure'
      }
      // invoke: {
      //   src: 'fetching',
      //   onDone: {
      //     target: 'success'
      //   },
      //   onError: 'failure'
      // }
    },
    success: {
      on: {
        CLOSE: '#ManageCourse.idle'
      }
    },
    failure: {
      on: {
        CLOSE: '#ManageCourse.idle'
      }
    }
  }
}

export default createMachine({
  id: 'ManageCourse',
  initial: 'idle',
  context: {
    page: 0,
    list: [],
    selected: null
  },
  states: {
    idle: {
      on: {
        SET_PAGE: {
          actions: ['setPage']
        },
        FETCH: 'fetch.process',
        FETCH_BG: 'fetchBg.process',
        INFO: {
          target: 'info.show',
          actions: ['setSelected']
        }
      }
    },
    fetch: fetchState,
    fetchBg: fetchState,
    info: {
      states: {
        show: {
          on: {
            CLOSE: '#ManageCourse.idle'
          }
        }
      }
    }
  }
}, {
  actions: {
    setSelected: assign({
      selected: (ctx, event) => event.data
    }),
    setList: assign({
      list: (ctx, event) => event.data
    }),
    setPage: assign({
      page: (ctx, event) => event.data
    })
  },
  services: {
    // fetching(ctx, event) {
    //   return new Promise((resolve, reject) => {
    //     useLazyQuery(COURSES, {
    //       onCompleted(data) {
    //         console.log(data)
    //         resolve(data)
    //       },
    //       onError(error) {
    //         console.error('Error on getting course', error)
    //         reject(error)
    //       }
    //     })
    //   })
    // }
  }
})