import React, {} from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom';
// MUI
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTheme, makeStyles, styled } from '@material-ui/core/styles';
// Icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';

const ValueGrid = styled(Grid)(({theme, ...props}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))


const useStyles = makeStyles((theme) => ({
  fontSize: {
    fontSize: '2em',
  },
  check: {
    fontSize: '2em',
    color: theme.palette.success.main
  },
  wrong: {
    fontSize: '2em',
    color: theme.palette.error.main
  },
  passIcon: {
    fontSize: '2em',
    color: theme.palette.success.main
  },
  failIcon: {
    fontSize: '1em',
    color: theme.palette.error.main
  }
}))

function Result({
  name,
  correct,
  wrong,
  items,
  grade,
  onRedirect
}) {
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()

  console.log(
    correct,
    wrong,
    items,
    grade
  )

  // const handleBack = () => {
  //   history.push('/')
  // }
  return (
    <Box elevation={5} style={{padding: theme.spacing(3)}}>
      <Typography variant="h6" align="center">{name}</Typography>
      <Typography variant="h3" align="center">Exam Result</Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item sm={3}>
        </Grid>
        <Grid item sm={6}>
          <Paper elevation={5} style={{backgroundColor: theme.palette.grey[200], padding: theme.spacing(3)}}>
            <Grid container spacing={3}>
              <Grid item sm={6}>
                <Typography variant="h5" align="center">
                  <CheckCircleOutlineIcon  className={classes.check}/>
                </Typography>
              </Grid>
              <ValueGrid item sm={6}>
                <Typography variant="h5" align="center">
                  <span>Correct : </span>
                  <span>{correct}</span>
                </Typography>
              </ValueGrid>
              <Grid item sm={6}>
                <Typography variant="h5" align="center">
                  <HighlightOffIcon  className={classes.wrong}/>
                </Typography>
              </Grid>
              <ValueGrid item sm={6}>
                <Typography variant="h5" align="center">
                  <span>Wrong : </span>
                  <span>{wrong}</span>
                </Typography>
              </ValueGrid>
              <Grid item sm={6}>
                <Typography variant="h5" align="center">
                  <FormatListNumberedIcon className={classes.fontSize}/>
                </Typography>
              </Grid>
              <ValueGrid item sm={6}>
                <Typography variant="h5" align="center">
                  <span>Total Items : </span>
                  <span>{items}</span>
                </Typography>
              </ValueGrid>
              <Grid item sm={6}>
                <Typography variant="h5" align="center">
                  <TrackChangesIcon className={classes.fontSize}/>
                </Typography>
              </Grid>
              <ValueGrid item sm={6}>
                <Typography variant="h5" align="center">
                  <span>Grade : </span>
                  <span>{grade}%</span>
                </Typography>
              </ValueGrid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Typography align="center">
        <Button variant="contained" color="primary" onClick={onRedirect}>Go Back</Button>
      </Typography>
    </Box>
  )
}
Result.propTypes = {
  name: PropTypes.string.isRequired,
  correct: PropTypes.number.isRequired,
  wrong: PropTypes.number.isRequired,
  items: PropTypes.number.isRequired,
  grade: PropTypes.number.isRequired,
  onRedirect: PropTypes.func
}
Result.defaultProps = {
  onRedirect: () => {}
}

export default Result