import React, { useEffect } from 'react';
// Material
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { logout } from 'reduxConfig/actions/currentUser';

import { LOGOUT_USER } from '../../constants/queries'
import { gql, useLazyQuery } from '@apollo/client'


// TODO Fix this error:
// Warning: Cannot update a component (`App`) while rendering a different component (`LogoutPage`).
// To locate the bad setState() call inside `LogoutPage`, follow the stack trace as described in https://fb.me/setstate-in-render
const LogoutPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [logoutUser, {}] = useLazyQuery(LOGOUT_USER, {
    onCompleted(data) {
      dispatch(logout())
      history.push('/');
    },
    onError(error) {
      dispatch(logout())
      history.push('/');
    }
  })

  // dispatch(logout());
  // history.push('/courses');
  useEffect(() => {
    logoutUser()
  }, [])
  return (
    <Backdrop open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default LogoutPage;
