import React, { memo, useState } from 'react';
import Button from './Button';
import TrialExamDialog from './TrialExamDialog';

const HeaderTrialExamButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (<>
    <Button onClick={openModal}>Trial Exam</Button>

    <TrialExamDialog
      isOpen={modalOpen}
      closeModal={closeModal}
    />
  </>)
}

export default memo(HeaderTrialExamButton)
