import { createMachine, assign } from 'xstate'

export default createMachine({
  id: 'Course',
  initial: 'idle',
  on: {
    FETCH: 'fetch.process',
    IMPORT: {
      target: 'import.confirm',
      actions: ['setFile']
    }
  },
  context: {
    file: null,
    info: null,
    functions: []
  },
  states: {
    idle: {},
    fetch: {
      states: {
        process: {
          on: {
            SUCCESS: {
              target: 'success',
              actions: ['setInfo']
            },
            FAILURE: 'failure'
          }
        },
        success: {
          on: {
            // CLOSE: '#Course.idle'
            CLOSE: '#Course.fetch.process'
          }
        },
        failure: {
          on: {
            RETRY: '#Course.fetch.process'
          }
        }
      }
    },
    import: {
      states: {
        confirm: {
          on: {
            CONFIRM: 'process',
            DISCARD: '#Course.idle'
          }
        },
        process: {
          on: {
            SUCCESS: 'success',
            FAILED: 'failure'
          }
        },
        success: {},
        failure: {
          on: {
            CLOSE: '#Course.idle'
          }
        }
      }
    }
  }
}, {
  actions: {
    setFile: assign({
      file: (ctx, event) => event.data
    }),
    setInfo: assign({
      info: (ctx, event) => event.data.detail,
      functions: (ctx, event) => event.data.functions
    })
  },
  services: {
    //
  }
})