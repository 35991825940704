import React, {} from 'react'
import PropTypes from 'prop-types'
// Material
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

const origin = {vertical: 'bottom', horizontal: 'right'};

function Loading({
  open,
  message
}) {

  return (
    <Snackbar
      open={open}
      anchorOrigin={origin}
      message={
        <div style={{display: 'flex', alignItems: 'center'}}>
          <CircularProgress color="inherit" size={20} /> &nbsp; {message}
        </div>
      }
    />
  )
}

Loading.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string
}


function Success({
  open,
  message,
  onClose
}) {

  return (
    <Snackbar open={open} onClose={onClose} anchorOrigin={origin} autoHideDuration={6000}>
      <Alert elevation={6} variant="filled" severity="success" onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  )
}
Success.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func
}


function Failure({
  open,
  message,
  onClose
}) {

  return (
    <Snackbar open={open} onClose={onClose} anchorOrigin={origin} autoHideDuration={6000}>
      <Alert elevation={6} variant="filled" severity="error" onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  )
}
Failure.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func
}



export default {
  Loading,
  Success,
  Failure
}